import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  briefAdded,
  briefChanged,
  briefRemoved,
  briefsLoaded,
  briefTemplateChanged,
  briefTemplateLoaded,
  briefTemplatesLoaded,
  getBrief,
  getBriefCount,
  getBriefs,
  getBriefTemplate,
  getBriefTemplates,
  getClosedBriefs,
  getOpenBriefs,
  getShouldRefreshBriefs,
} from 'features/briefs/briefs.slice';
import { CloseBrief } from 'features/briefs/interfaces';
import { creatorsAdded } from 'features/creators/creators.slice';

import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_BRIEF_ROUTES } from 'shared/config/routes/api/apiBriefRoutes';
import { BRIEF_TOASTS } from 'shared/config/toasts';
import { CONSTRAINT_LIMITS } from 'shared/config/validations';
import { noOp } from 'shared/defaults';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { intl } from 'shared/lib/intl';
import { ApiOnSuccess, PushFunction } from 'shared/typings/api';
import { Brief } from 'shared/typings/briefs';
import { BriefStatus } from 'shared/typings/briefs/enums';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

const APPLY_TO_BRIEF = 'applyToBrief';
const ACCEPT_BRIEF_INVITE = 'acceptBriefInvite';
const ELLIPSIS = '...';

interface BriefCheckoutConfig {
  data: any;
  pushFunction: PushFunction;
  successToast?: {
    message: string;
  };
  isNew?: boolean;
  onSuccess?: ApiOnSuccess;
  isSavingDraft?: boolean;
}

export const useBriefs = (briefId?) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const briefs = useSelector(getBriefs, shallowEqual);
  const openBriefs = useSelector(getOpenBriefs);
  const closedBriefs = useSelector(getClosedBriefs);
  const publishedBriefs = [...openBriefs, ...closedBriefs];
  const briefTemplates = useSelector(getBriefTemplates);
  const briefTemplate = useSelector(getBriefTemplate(briefId));
  // TODO: Fix Types
  // @ts-ignore
  const brief: Brief = useSelector(getBrief(+briefId));
  const totalBriefs = useSelector(getBriefCount);
  const shouldRefreshBriefs = useSelector(getShouldRefreshBriefs);
  const { currentUser } = useCurrentUser();
  const { loading } = useComponentLoading(RESOURCES.BRIEF, true);
  const { loading: applyToBriefActionInProgress } = useComponentLoading(APPLY_TO_BRIEF, false);
  const { loading: acceptBriefInviteActionInProgress } = useComponentLoading(ACCEPT_BRIEF_INVITE, false);
  const { loading: loadingBriefAssets } = useComponentLoading(RESOURCES.BRIEF_ASSET, false);
  const { loading: loadingBriefCheckout } = useComponentLoading(RESOURCES.BRIEF_PUBLISHING, false);
  const { loading: loadingSavingBriefDraft } = useComponentLoading(RESOURCES.BRIEF_SAVING_DRAFT, false);
  const { loading: loadingBriefTemplates } = useComponentLoading(RESOURCES.BRIEF_TEMPLATES, false);

  const fetchBriefs = (query) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.BRIEFS,
        },
        params: query,
        successAction: briefsLoaded,
        entity: RESOURCES.BRIEF,
      }),
    );
  };

  const fetchBrief = (id) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.BRIEF,
          variables: {
            briefId: id,
          },
        },
        method: 'GET',
        successAction: briefAdded,
        entity: RESOURCES.BRIEF,
      }),
    );
  };

  const fetchBriefTemplate = (id) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.BRIEF,
          variables: {
            briefId: id,
          },
        },
        method: 'GET',
        successAction: briefTemplateLoaded,
        entity: RESOURCES.BRIEF_TEMPLATES,
      }),
    );
  };

  const fetchBriefTemplates = () => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.BRIEF_TEMPLATES,
        },
        method: 'GET',
        successAction: briefTemplatesLoaded,
        entity: RESOURCES.BRIEF_TEMPLATES,
      }),
    );
  };

  const deleteBrief = (id, pushFunction = undefined) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.BRIEF,
          variables: {
            briefId: id,
          },
        },
        method: 'DELETE',
        successAction: briefRemoved,
        data: { id },
        pushFunction,
        navigate,
        successToast: {
          message: BRIEF_TOASTS.BRIEF_DELETED,
        },
      }),
    );
  };

  const closeBrief: CloseBrief = (id, pushFunction = undefined) => {
    const currentBrief = briefs.find((b) => b.id === id);

    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.CLOSE_BRIEF,
          variables: {
            briefId: id,
          },
        },
        method: 'PATCH',
        successAction: briefChanged,
        navigate,
        pushFunction,
        entity: RESOURCES.BRIEF,
        successToast: {
          message: intl.formatMessage(
            {
              id: 'BRIEF_CLOSED',
            },
            {
              name: currentBrief?.name,
            },
          ),
        },
      }),
    );
  };
  const pauseBrief = (currentBrief, pushFunction = undefined) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.PAUSE_BRIEF,
          variables: {
            briefId: currentBrief.id,
          },
        },
        method: 'PATCH',
        successAction: briefChanged,
        navigate,
        pushFunction,
        entity: RESOURCES.BRIEF,
        successToast: {
          message: intl.formatMessage(
            {
              id: 'BRIEF_PAUSED',
            },
            {
              name: currentBrief.name,
            },
          ),
        },
      }),
    );
  };

  const resumeBrief = (currentBrief, pushFunction = undefined) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.RESUME_BRIEF,
          variables: {
            briefId: currentBrief.id,
          },
        },
        method: 'PATCH',
        successAction: briefChanged,
        navigate,
        pushFunction,
        entity: RESOURCES.BRIEF,
        successToast: {
          message: intl.formatMessage(
            {
              id: 'BRIEF_RESUMED',
            },
            {
              name: currentBrief.name,
            },
          ),
        },
      }),
    );
  };

  const reopenBrief = (id) => {
    const currentBrief = briefs.find((b) => b.id === id);
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.REOPEN_BRIEF,
          variables: {
            briefId: id,
          },
        },
        method: 'PATCH',
        successAction: briefChanged,
        navigate,
        entity: RESOURCES.BRIEF,
        pushFunction: (data) => {
          return LinkCreator.createLink({
            routeKey: 'BRIEF_INDEX',
            variables: {
              organizationId: currentBrief?.organizationId,
              accountId: currentBrief?.accountId,
            },
            query: {
              item: data.id,
            },
          });
        },
        successToast: {
          message: intl.formatMessage(
            {
              id: 'BRIEF_REOPENED',
            },
            {
              name: currentBrief?.name,
            },
          ),
        },
      }),
    );
  };

  const publishBrief = (id, pushFunction) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.PUBLISH_BRIEF,
          variables: {
            briefId: id,
          },
        },
        method: 'PATCH',
        successAction: briefChanged,
        navigate,
        entity: RESOURCES.BRIEF,
        pushFunction,
      }),
    );
  };

  const briefCheckout = ({
    data,
    pushFunction,
    successToast,
    onSuccess = noOp,
    isNew = false,
    isSavingDraft = false,
  }: BriefCheckoutConfig) => {
    const checkoutData = data.id
      ? {
          ...data,
          pathToBrief: LinkCreator.createLink({
            routeKey: 'VIEW_INDIVIDUAL_BRIEF',
            userType: UserType.CREATOR,
            variables: {
              briefId: data.id,
            },
          }),
        }
      : data;

    dispatch(
      apiAction({
        path: API_BRIEF_ROUTES.CHECKOUT,
        method: 'POST',
        data: checkoutData,
        successAction: isNew ? briefAdded : briefChanged,
        onSuccess,
        pushFunction,
        navigate,
        successToast,
        entity: isSavingDraft ? RESOURCES.BRIEF_SAVING_DRAFT : RESOURCES.BRIEF_PUBLISHING,
      }),
    );
  };

  const editBriefTemplate = ({ data, onSuccess }) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.EDIT_TEMPLATE,
          variables: {
            briefTemplateId: data.id,
          },
        },
        method: 'PATCH',
        data,
        successToast: { message: BRIEF_TOASTS.BRIEF_TEMPLATE_UPDATED },
        onSuccess: onSuccess,
        successAction: briefTemplateChanged,
      }),
    );
  };

  const parseDuplicateBriefName = (briefName) => {
    let duplicateBriefName = intl.formatMessage({ id: 'LABEL_COPY_OF_BRIEF' }, { briefName });
    if (duplicateBriefName.length > CONSTRAINT_LIMITS.MAX_BRIEF_TITLE_LENGTH) {
      const extraCharacters = duplicateBriefName.length - CONSTRAINT_LIMITS.MAX_BRIEF_TITLE_LENGTH + ELLIPSIS.length;
      duplicateBriefName = duplicateBriefName
        .substring(0, duplicateBriefName.length - extraCharacters)
        .concat(ELLIPSIS);
    }
    return duplicateBriefName;
  };

  const duplicateBrief = (briefToDuplicate) => {
    const parsedBrief = { ...briefToDuplicate, name: parseDuplicateBriefName(briefToDuplicate.name) };
    const toastMessage = intl.formatMessage(
      {
        id: 'BRIEF_CREATED',
      },
      {
        name: parsedBrief.name,
      },
    );

    const { brandAssets, id, modules, website, ...briefValues } = parsedBrief;
    const updatedBrief = {
      ...briefValues,
      modules: modules.map(({ id, briefId, scenes, ...mod }) => ({
        ...mod,
        scenes: scenes.map(({ id, ...scene }) => ({
          ...scene,
        })),
      })),
      brandAssets: brandAssets.map((asset) => asset.id),
      status: BriefStatus.DRAFT,
      validateCheckout: false,
      website: website || '',
    };
    briefCheckout({
      data: updatedBrief,
      pushFunction: (data) =>
        LinkCreator.createLink({
          routeKey: 'BRIEF_INDEX',
          variables: {
            organizationId: data.organizationId,
            accountId: data.accountId,
          },
          query: {
            item: data.id,
          },
        }),
      successToast: { message: toastMessage },
      isNew: true,
    });
  };

  const applyToBrief = (id: number, applicationPitch = '', briefInStoreRequirementIds) => {
    // apply to specific brief, only for creators who are pre-approved by the brand
    // or already have a pending brief with the same brand
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.APPLY_TO_BRIEF,
          variables: {
            briefId: id,
          },
        },
        method: 'POST',
        data: {
          creatorId: currentUser.id,
          applicationPitch,
          briefInStoreRequirementIds,
        },
        successAction: briefChanged,
        successToast: {
          message: BRIEF_TOASTS.APPLIED_BRIEF,
        },
        entity: APPLY_TO_BRIEF,
      }),
    );
  };

  const acceptBriefInvite = (config: { id?: number; briefInStoreRequirementIds?: number[]; pitch?: string } = {}) => {
    const { id = briefId, briefInStoreRequirementIds = [], pitch = '' } = config;
    if (id) {
      dispatch(
        apiAction({
          path: {
            route: API_BRIEF_ROUTES.ACCEPT_INVITATION,
            variables: {
              briefId: id,
              creatorId: currentUser.id,
            },
          },
          data: {
            briefInStoreRequirementIds,
            pitch,
          },
          method: 'POST',
          successAction: briefChanged,
          entity: ACCEPT_BRIEF_INVITE,
        }),
      );
    }
  };

  const inviteCreators = (data) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.INVITE_CREATORS,
          variables: {
            briefId,
          },
        },
        method: 'POST',
        data,
        entity: RESOURCES.BRIEF,
        successAction: creatorsAdded,
        onSuccess: data.onSuccess,
        successToast: {
          message: data.emails.length === 1 ? BRIEF_TOASTS.CREATOR_INVITED : BRIEF_TOASTS.CREATORS_INVITED,
        },
      }),
    );
  };

  const editBrief = (currentBrief: Brief) => {
    navigate(
      LinkCreator.createLink({
        routeKey: 'EDIT_BRIEF',
        variables: {
          briefId: currentBrief.id,
          organizationId: currentBrief.organizationId,
          accountId: currentBrief.accountId,
        },
      }),
    );
  };

  return {
    acceptBriefInvite,
    acceptBriefInviteActionInProgress,
    applyToBrief,
    applyToBriefActionInProgress,
    brief,
    briefCheckout,
    briefs,
    closeBrief,
    duplicateBrief,
    reopenBrief,
    deleteBrief,
    editBrief,
    pauseBrief,
    resumeBrief,
    fetchBrief,
    fetchBriefs,
    inviteCreators,
    loading,
    loadingBriefAssets,
    loadingBriefCheckout,
    loadingSavingBriefDraft,
    loadingBriefTemplates,
    openBriefs,
    publishBrief,
    publishedBriefs,
    shouldRefreshBriefs,
    totalBriefs,
    briefTemplates,
    briefTemplate,
    fetchBriefTemplates,
    fetchBriefTemplate,
    editBriefTemplate,
  };
};
