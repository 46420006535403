import { css, keyframes } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

export const getCreatorsTableStyles = (width: string, height: string, numItems: number) =>
  css({
    '&.creators-table': {
      margin: '24px 0',
      display: 'grid',
      gridTemplateColumns: numItems >= 4 ? `repeat(auto-fit, minmax(${width}, 1fr))` : `repeat(auto-fit, ${width})`,
      gridTemplateRows: height,
      gridGap: theme.space[4],
      width: `100%`,
      justifyItems: 'center',
      [mediaquery.md]: {
        gridGap: theme.space[5],
      },
    },
  });

export const creatorsTableNoDataStyles = css({
  '.creators-table': {
    '&__no-creator, &__no-brief': {
      animation: `${fadeIn} 0.01s`,
      opacity: 0,
      animationDelay: '0.1s',
      animationFillMode: 'forwards',
      textAlign: 'center',
    },
  },
});
