import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export const controlStyles = {
  control: (base, state) => ({
    ...base,
    borderColor:
      (state.isDisabled && COLORS.NEUTRAL500) ||
      (state.selectProps.hasErrors && COLORS.RED500) ||
      ((state.isFocused || state.selectProps.menuIsOpen) && COLORS.PRIMARY500) ||
      (state.selectProps.hasValue && base.borderColor) ||
      base.borderColor,
    boxShadow: state.selectProps.hasErrors ? 'none' : base.boxShadow,
    position: 'relative',
    margin: '.75rem 0 .25rem',
    height: !state.isMulti && '42px',
    borderRadius: '2px',
    paddingLeft: Boolean(state.selectProps.ControlPrefix) ? theme.space[3] : 0,
    '&:hover': {
      borderColor:
        (state.isDisabled && COLORS.NEUTRAL500) ||
        (state.selectProps.hasErrors && COLORS.RED500) ||
        ((state.isFocused || state.selectProps.menuIsOpen) && COLORS.PRIMARY500) ||
        (state.selectProps.hasValue && base.borderColor) ||
        base.borderColor,
    },
  }),
};
