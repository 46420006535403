import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import text, { fontSizes } from 'shared/styles/text';

const getStyles = ({ color }) =>
  css({
    '&.account-health-contributor': {
      position: 'relative',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'left',
      height: '54px',
      overflow: 'hidden',
      backgroundColor: COLORS.WHITE,
      borderRadius: '8px',
      marginBottom: '10px',
      padding: 0,
      boxShadow: `0px 4px 5px 1px ${COLORS.NEUTRAL200}`,
      cursor: 'pointer',
      maxHeight: '500px',
    },
    '&.account-health-contributor--expanded': {
      height: 'auto',
      overflow: 'auto',
      paddingBottom: '30px',
    },
    '&:before': {
      content: '""',
      backgroundColor: color,
      height: '100%',
      width: '10px',
      position: 'absolute',
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
      zIndex: zIndex.zForeground,
    },
    '.account-health-contributor': {
      '&__top-section': {
        width: '100%',
        padding: '0 20px',
      },
      '&__top-section-text': {
        flexDirection: 'column',
        padding: '5px 0',
      },
      '&__title': {
        margin: '0 0 -6px 0',
      },
      '&__description': {
        lineHeight: '1.4',
        padding: '10px 0 40px 0',
        [mediaquery.md]: {
          paddingBottom: '20px',
        },
      },
      '&__bottom-section': {
        width: '100%',
        borderTop: `1px solid ${COLORS.NEUTRAL200}`,
        transition: 'maxHeight 0.2s ease, padding 0.2s ease',
        overflow: 'hidden',
        flexDirection: 'column',
        padding: '0 20px',
        '&--expanded': {
          height: 'auto',
          padding: '0 20px 10px 25px',
          [mediaquery.md]: {
            padding: '0 20px 5px 20px',
            height: 'auto',
          },
        },
      },
      '&__description,&__value': {
        margin: 0,
      },
      '&__impact': {
        position: 'absolute',
        bottom: '15px',
        margin: 0,
      },
    },
    '& .CircularProgressbar-text ': {
      fill: COLORS.BLACK,
      transformOrigin: 'center',
      transform: 'rotate(180deg)',
      ...text.h1,
      fontSize: fontSizes[6],
    },
  });

export default getStyles;
