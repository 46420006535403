import React from 'react';
import cs from 'classnames';

import CreatorUserFields from 'features/users/components/CreatorUserFields/CreatorUserFields';
import InternalNotesForm from 'features/users/components/InternalNotesForm/InternalNotesForm';
import CreatorTags from 'features/users/components/molecules/CreatorTags/CreatorTags';
import { ProfilePicture } from 'features/users/components/molecules/ProfilePicture';
import PayoutForm from 'features/users/components/organisms/PayoutForm/PayoutForm';
import CreatorAddress from 'features/users/CreatorAddress';
import { Box, Flex, Text } from 'shared/components/display';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';

import { USER_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstTag } from 'shared/typings/tag';
import { BrkfstUser, CreatorUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { getAgeFromDate } from 'shared/utilities/dateUtility';
import BrandApprovedBadge from 'features/users/components/atoms/BrandApprovedBadge';
import EliteCreatorBadge from 'features/users/components/atoms/EliteCreatorBadge';
import EliteCreatorButton from 'features/users/components/organisms/EliteCreatorButton';

import creatorInfoPanelStyles from './creatorInfoPanelStyles';
import EllipticalMenu from 'shared/components/EllipticalMenu/EllipticalMenu';
import { intl } from 'shared/lib/intl';
import VettingApprovalButtons from 'features/creators/components/atoms/VettingApprovalButtons';

interface Props {
  user: BrkfstUser;
  creatorTags: BrkfstTag[];
}
const MarketerView: React.FC<Props> = ({ user, creatorTags }) => {
  const { isSuperadmin } = useCurrentUser();

  const age =
    user.type === UserType.CREATOR
      ? user.birthdate
        ? getAgeFromDate(user.birthdate)
        : creatorTags.find((tag) => tag.category.toLowerCase() === 'age')?.value
      : null;

  // Typescript requires type validation to access BrkfstUser properties
  const gender =
    user.type === UserType.CREATOR
      ? user.gender
        ? user.gender
        : creatorTags.find((tag) => tag.category.toLowerCase() === 'gender')?.value
      : null;

  const genderAgeLabel = `${gender ? gender : ''}${gender && age ? ', ' : ''}${age?.toString() ? age : ''}`;
  return (
    <Box as="section" css={creatorInfoPanelStyles} className={cs('profile-panel', 'profile-panel--white')}>
      <Box className={cs('profile-panel__item', 'profile-panel__modal-form-btn')}>
        {isSuperadmin && (
          <EllipticalMenu
            id={'profile-panel__admin-btn-group'}
            className="profile-panel__admin-btn-group"
            dataCy="profile-panel__admin-btn-group"
            width="225px"
          >
            <PayoutForm user={user} />
            <InternalNotesForm user={user} />
            <EliteCreatorButton className="profile-panel__elite" user={user} />
            <VettingApprovalButtons creator={user} portalTarget="#profile-panel__admin-btn-group" />
          </EllipticalMenu>
        )}
        <ProfilePicture user={user} size="lg" disableNavigate />
      </Box>
      <h4 className={cs('profile-panel__header', 'profile-panel__header--centered')}>
        {`${user.firstName} ${user.lastName || ''}`}
      </h4>
      <Text className="profile-panel__gender-age" variant={TEXT_VARIANTS.BODY}>
        {genderAgeLabel}
      </Text>
      <Flex className="profile-panel__badge-wrapper">
        {user.approvedAssetBadge && <BrandApprovedBadge showTooltip />}
        {user.isElite && <EliteCreatorBadge showTooltip />}
      </Flex>
      {user.summary && <Text className="profile-panel__item">{user.summary}</Text>}
      <Box className={cs('profile-panel__item', 'profile-panel__tags')}>
        <CreatorTags tags={creatorTags} />
      </Box>
      {isSuperadmin && (
        <Box className="profile-panel__item">
          <DefinitionList>
            <ListDetail text="Internal Note" hide={!user.note}>
              {user.note || ''}
            </ListDetail>
            <ListDetail text="Email">
              <a href={`mailto:${user.email}`} target="_blank">
                {user.email}
              </a>
            </ListDetail>
            <ListDetail text={USER_COPY.LABEL_ACTIVE_BRIEFS}>
              {`${(user as CreatorUser)?.inProgressBriefsCount || 0}`}
            </ListDetail>
          </DefinitionList>
        </Box>
      )}
      <Box className="profile-panel__item">
        <CreatorUserFields user={user} />
        <CreatorAddress userId={user.id} />
      </Box>
    </Box>
  );
};

export default MarketerView;
