import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import cs from 'classnames';

import CenteredSpinner from 'features/ui/CenteredSpinner';
import CreatorOnboardingConfirmation from 'features/users/components/CreatorOnboardingConfirmation/CreatorOnboardingConfirmation';
import {
  AgeFormFields,
  ageInitialValues,
  ageSchema,
  AgeValues,
  EthnicityFormFields,
  ethnicityOtherOption,
  ethnicitySchema,
  EthnicityValues,
  formatDependentDOB,
  formatInitialWorkPrefValues,
  GenderFormFields,
  genderInitialValues,
  genderOtherOption,
  genderSchema,
  GenderValues,
  getPortfolioSchema,
  LocationFormFields,
  locationSchema,
  LocationValues,
  ModelAvailabilityFormFields,
  modelSchema,
  ModelValues,
  notificationInitialValues,
  NotificationsFormFields,
  notificationsSchema,
  portfolioInitialValues,
  PortfolioOnboardingStepFields,
  StripeFormFields,
  stripeInitialValues,
  stripeSchema,
  WorkPreferencesFormFields,
  workPreferencesSchema,
  WorkPreferencesValues,
} from 'features/users/components/CreatorOnboardingStepsFields';
import {
  HeightFormFields,
  heightInitialValues,
  heightSchema,
  HeightValues,
} from 'features/users/components/CreatorOnboardingStepsFields/HeightFormFields';
import {
  LanguageFormFields,
  languageOtherOption,
  languageSchema,
  LanguageValues,
} from 'features/users/components/CreatorOnboardingStepsFields/LanguageFormFields';
import {
  referralInitialValues,
  ReferralOnboardingStepFields,
  referralSchema,
  ReferralValues,
} from 'features/users/components/CreatorOnboardingStepsFields/ReferralOnboardingStepFields';
import { useUser } from 'features/users/hooks/useUser';
import { CREATOR_ONBOARDING_STEPS } from 'features/users/pages/Onboarding/enums';
import creatorOnboardingStyles from 'features/users/pages/Onboarding/styles';
import { Box } from 'shared/components/display';
import { MultiStepForm, StepForm } from 'shared/components/forms/MultiStepForm';
import {
  multiStepFormCss,
  SplitPaneStepWrapper,
} from 'shared/components/forms/MultiStepForm/Steps/SplitPaneStepWrapper';
import VerticalStepWrapper from 'shared/components/forms/MultiStepForm/Steps/VerticalStepWrapper/VerticalStepWrapper';
import {
  AddressFormFields,
  addressPhoneOnSubmit,
  addressSchemaWithPhone,
} from 'shared/components/organisms/AddressFormFields';
import { addressInitialValues } from 'shared/components/organisms/AddressFormFields/constants';

import axiosClient from 'shared/axiosClient';
import { CREATOR_COPY } from 'shared/config/copy';
import { API_TAG_ROUTES } from 'shared/config/routes/api/apiTagRoutes';
import { LOCAL_STORAGE } from 'shared/config/storage';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useGeoLocation } from 'shared/hooks/useGeoLocationHook';
import { CLASSNAMES } from 'shared/styles/containers';
import { breakpoints } from 'shared/styles/styleFunctions';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { capitalizeWithoutConjunctions } from 'shared/utilities/stringUtility';
import { formatInitialModelValues } from 'shared/utilities/tagUtility';

import { CreatorOnboardingPayload } from './types';

const NUM_STEPS = 13;
type State = {
  ethnicity: EthnicityValues;
  workPreference: WorkPreferencesValues;
  language: LanguageValues;
  location: LocationValues;
  model: ModelValues;
  loading: boolean;
};

const CreatorOnboarding: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });
  const { onboardCreator, loadingOnboarding } = useUser();
  const { validateAddress } = useGeoLocation();
  const [addressError, setAddressError] = useState<string[]>(['']);
  const [initialValues, setInitialValues] = useState<State>({
    ethnicity: {
      ethnicity: [],
      ethnicityOther: '',
    },
    workPreference: {
      workType: [],
      editing: [],
    },
    language: {
      language: [],
      languageOther: '',
    },
    location: {
      locations: [],
    },
    model: { models: [] },
    loading: true,
  });

  const stripeRedirectUri = LinkCreator.createLink({
    userType: UserType.CREATOR,
    routeKey: 'CREATOR_ONBOARDING',
  });

  useEffect(() => {
    axiosClient
      .httpRequest<{ category: string; values: string[] }[]>({
        url: API_TAG_ROUTES.TAG_CATEGORIES,
        method: 'GET',
      })
      .then(({ data }) => {
        const tags = data.reduce((acc, val) => {
          acc[val.category] = val.values.map((value) => ({
            label: value.toUpperCase() === value ? value : capitalizeWithoutConjunctions(value),
            value,
          }));
          return acc;
        }, {});

        setInitialValues({
          ethnicity: {
            ethnicity: [...tags['ethnicity'], ethnicityOtherOption],
            ethnicityOther: '',
          },
          workPreference: { workType: tags['work preference'], editing: tags['editing'] },
          language: { language: [...tags['language'], languageOtherOption], languageOther: '' },
          location: { locations: tags['location'] },
          model: { models: tags['model'] },
          loading: false,
        });
      });
  }, []);

  const onConfirm = (values, clearDataFromLocalStorage) => {
    const payload = formatOnboardingInfo(values);
    onboardCreator(payload, clearDataFromLocalStorage);
  };

  return (
    <Box css={creatorOnboardingStyles} className={cs(CLASSNAMES.MAIN_CONTAINER, 'creator-onboarding')}>
      {loadingOnboarding || initialValues.loading ? (
        <CenteredSpinner />
      ) : (
        <MultiStepForm
          localStorageKey={LOCAL_STORAGE.CREATOR_ONBOARDING}
          StepWrapper={isMobile ? VerticalStepWrapper : SplitPaneStepWrapper}
          ConfirmationPage={<CreatorOnboardingConfirmation user={currentUser} />}
          onConfirm={onConfirm}
          confirmationTitle={'Confirm your account details'}
          css={multiStepFormCss(NUM_STEPS)}
        >
          {/* Due to how props are passed down from MultiStepFrom to StepForm, StepForm must be the direct child of MultiStepForm */}
          <StepForm
            title={CREATOR_COPY.HEADER_PORTFOLIO_SHOWCASE}
            initialValues={portfolioInitialValues}
            validationSchema={getPortfolioSchema(!!currentUser.instagramProfile)}
            submitText="Save & Continue"
            disableUntilTouched
          >
            <PortfolioOnboardingStepFields currentUser={currentUser} />
          </StepForm>
          <StepForm
            title={CREATOR_COPY.HEADER_CREATOR_ADDRESS}
            initialValues={addressInitialValues}
            validationSchema={addressSchemaWithPhone}
            onSubmit={addressPhoneOnSubmit(validateAddress, setAddressError)}
            submitText="Save & Continue"
          >
            <AddressFormFields error={addressError} />
          </StepForm>
          <StepForm
            title={CREATOR_COPY.HEADER_AGE_ONBOARDING}
            initialValues={ageInitialValues}
            validationSchema={ageSchema}
            submitText="Save & Continue"
            disableUntilTouched
          >
            <AgeFormFields className="age__caption" />
          </StepForm>
          <StepForm
            title={CREATOR_COPY.HEADER_ETHNICITY_ONBOARDING}
            initialValues={initialValues.ethnicity}
            validationSchema={ethnicitySchema}
            isOptional
            hideUntilTouched
            submitText="Save & Continue"
          >
            <EthnicityFormFields className="onboarding-steps__select-multiple" />
          </StepForm>
          <StepForm
            title={CREATOR_COPY.HEADER_GENDER_ONBOARDING}
            initialValues={genderInitialValues}
            validationSchema={genderSchema}
            isOptional
            hideUntilTouched
            submitText="Save & Continue"
          >
            <GenderFormFields />
          </StepForm>
          <StepForm
            title={CREATOR_COPY.HEADER_HEIGHT_ONBOARDING}
            initialValues={heightInitialValues}
            validationSchema={heightSchema}
            isOptional
            hideUntilTouched
            submitText="Save & Continue"
          >
            <HeightFormFields />
          </StepForm>
          <StepForm
            title={CREATOR_COPY.HEADER_LANGUAGE_ONBOARDING}
            initialValues={initialValues.language}
            validationSchema={languageSchema}
            isOptional
            hideUntilTouched
            submitText="Save & Continue"
          >
            <LanguageFormFields className="onboarding-steps__select-multiple" />
          </StepForm>
          <StepForm
            title={CREATOR_COPY.HEADER_WORK_PREFERENCES}
            initialValues={formatInitialWorkPrefValues(initialValues.workPreference)}
            validationSchema={workPreferencesSchema}
            submitText="Save & Continue"
            disableUntilTouched
          >
            <WorkPreferencesFormFields className="work-pref__caption" />
          </StepForm>
          <StepForm
            title={CREATOR_COPY.HEADER_MODEL_ONBOARDING}
            initialValues={formatInitialModelValues(initialValues.model.models)}
            validationSchema={modelSchema}
            isOptional
            hideUntilTouched
            submitText="Save & Continue"
          >
            <ModelAvailabilityFormFields className="onboarding-steps__select-multiple" />
          </StepForm>
          <StepForm
            title={CREATOR_COPY.HEADER_LOCATION_ONBOARDING}
            initialValues={initialValues.location}
            validationSchema={locationSchema}
            isOptional
            hideUntilTouched
            submitText="Save & Continue"
          >
            <LocationFormFields className="onboarding-steps__select-multiple" />
          </StepForm>
          <StepForm
            title={CREATOR_COPY.HEADER_CONNECT_STRIPE}
            initialValues={stripeInitialValues}
            validationSchema={stripeSchema}
            isDeferred={!currentUser.stripeAccountId}
            submitText="Save & Continue"
          >
            <StripeFormFields user={currentUser} redirectURI={stripeRedirectUri} />
          </StepForm>
          <StepForm
            title={CREATOR_COPY.HEADER_PHONE_NOTIFICATIONS}
            initialValues={notificationInitialValues}
            validationSchema={notificationsSchema}
            isOptional
            hideUntilTouched
            submitText="Save & Continue"
          >
            <NotificationsFormFields />
          </StepForm>
          <StepForm
            title={CREATOR_COPY.HEADER_REFERRAL_ONBOARDING}
            initialValues={referralInitialValues}
            validationSchema={referralSchema}
            disableUntilTouched
            submitText="Save & Continue"
          >
            <ReferralOnboardingStepFields />
          </StepForm>
        </MultiStepForm>
      )}
    </Box>
  );
};

export const getSelectedValues = ({
  data,
  prefix,
  otherPrefix,
  otherOption,
  otherValue,
}: {
  data;
  prefix: string;
  otherPrefix?: string;
  otherOption?;
  otherValue?: string;
}): string[] => {
  return (data || [])
    .filter(({ selected }) => selected)
    .map(({ value }) => {
      if (value === otherOption?.value) {
        return `${otherPrefix || prefix}:${otherValue}`;
      }
      return `${prefix}:${value}`;
    });
};

export function formatOnboardingInfo(values): CreatorOnboardingPayload {
  const portfolioData = values[CREATOR_ONBOARDING_STEPS.PORTFOLIO];
  const {
    country: { value: country },
    state: { value: state },
    ...addressData
  } = values[CREATOR_ONBOARDING_STEPS.ADDRESS];
  const notificationData = values[CREATOR_ONBOARDING_STEPS.NOTIFICATIONS];
  const ageData: AgeValues = values[CREATOR_ONBOARDING_STEPS.DOB];
  const genderData: GenderValues = values[CREATOR_ONBOARDING_STEPS.GENDER];
  const heightData: HeightValues = values[CREATOR_ONBOARDING_STEPS.HEIGHT];
  const referralData: ReferralValues = values[CREATOR_ONBOARDING_STEPS.REFERRAL];
  // tags
  const workPreferenceData: WorkPreferencesValues = values[CREATOR_ONBOARDING_STEPS.WORK_PREFERENCES];
  const languageData: LanguageValues = values[CREATOR_ONBOARDING_STEPS.LANGUAGE];
  const ethnicityData: EthnicityValues = values[CREATOR_ONBOARDING_STEPS.ETHNICITY];
  const locationData: LocationValues = values[CREATOR_ONBOARDING_STEPS.LOCATION];
  const modelData: ModelValues = values[CREATOR_ONBOARDING_STEPS.MODEL];

  const stateId = state?.id || null; // Null if no state to remove relation if one already exists

  const ethnicityValues = getSelectedValues({
    data: ethnicityData?.ethnicity,
    prefix: 'ethnicity',
    otherOption: ethnicityOtherOption,
    otherValue: ethnicityData?.ethnicityOther,
  });
  const languageValues = getSelectedValues({
    data: languageData?.language,
    prefix: 'language',
    otherOption: languageOtherOption,
    otherValue: languageData?.languageOther,
  });

  const modelValues = getSelectedValues({ data: modelData?.models, prefix: 'model' });
  const locationValues = getSelectedValues({ data: locationData?.locations, prefix: 'location' });
  const workTypeValues = getSelectedValues({ data: workPreferenceData.workType, prefix: 'work preference' });
  const editingValues = getSelectedValues({ data: workPreferenceData.editing, prefix: 'editing' });

  const allTags = [
    ...ethnicityValues,
    ...modelValues,
    ...locationValues,
    ...workTypeValues,
    ...editingValues,
    ...languageValues,
  ];
  return {
    portfolioLink: portfolioData.portfolioLink,
    tiktokHandle: portfolioData.tiktokHandle,
    address: { ...addressData, countryId: country.id, stateId },
    notifications: notificationData?.optIn || false,
    height: heightData?.height?.value,
    birthdate: ageData.dob,
    gender:
      (genderData?.gender || []).find(({ selected }) => selected)?.value === genderOtherOption.value
        ? genderData.genderOther
        : (genderData?.gender || []).find(({ selected }) => selected)?.value,
    referralSource: (referralData?.referral || [])
      .filter(({ selected }) => selected)
      .map(({ value }) => value)
      .concat(referralData.referralOther ? [referralData.referralOther] : [])
      .toString(),
    tags: allTags,
    dependents: modelData.children
      ?.concat(modelData.teenagers || [])
      .map(({ dob }) => ({ birthdate: formatDependentDOB(dob) })),
  };
}

export default CreatorOnboarding;
