import React, { useLayoutEffect, useState } from 'react';
import ReactSelect, { createFilter } from 'react-select';
import cs from 'classnames';
import { isEmpty } from 'lodash';

import { controlStyles, SelectControl } from 'shared/components/atoms/SelectControl';
import { multiValueStyles, SelectMultiValue } from 'shared/components/atoms/SelectMultiValueContainer';
import { SelectOption } from 'shared/components/atoms/SelectOption';
import { SelectValueContainer, valueContainerStyles } from 'shared/components/atoms/SelectValueContainer';
import { MODAL_PORTAL_TARGET_SELECTOR } from 'shared/components/molecules/Modal';

import { SelectProps } from './interfaces';

const components = {
  ValueContainer: SelectValueContainer,
  MultiValue: SelectMultiValue,
  Option: SelectOption,
  Control: SelectControl,
};

const selectStyles = {
  ...valueContainerStyles,
  ...multiValueStyles,
  ...controlStyles,
};

const Select: React.FC<SelectProps> = ({
  options,
  value,
  name,
  width,
  placeholder,
  isMulti = false,
  isOptionSelected,
  onChange,
  hasErrors = false,
  menuPortalTarget = MODAL_PORTAL_TARGET_SELECTOR,
  disabled: isDisabled = false,
  hidePlaceholder = false,
  showSelectAll = true,
  maxMenuHeight,
  closeMenuOnSelect = true,
  isSearchable = false,
  className,
  filterByLabel = false,
  showOptionTooltip = false,
  capitalizePlaceholder = true,
  ControlPrefix,
  dataCy,
  menuIsOpen,
}) => {
  const [portalTarget, setPortalTarget] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    const target = document.querySelector<HTMLElement>(menuPortalTarget);
    setPortalTarget(target);
  }, []);

  const labelFilter = createFilter({
    matchFrom: 'any',
    stringify: (option) => `${option.label}`,
  });

  return (
    <ReactSelect
      isOptionSelected={isOptionSelected}
      className={cs(className)}
      isSearchable={isSearchable}
      // Whether the select menu should use a portal, and where it should attach.
      menuPortalTarget={portalTarget}
      components={components}
      // @ts-ignore
      hidePlaceholder={hidePlaceholder}
      showOptionTooltip={showOptionTooltip}
      isMulti={isMulti}
      name={name}
      placeholder={placeholder}
      styles={selectStyles}
      options={options}
      onChange={onChange}
      value={value}
      menuPlacement="auto"
      isDisabled={isDisabled}
      // @ts-ignore
      hasValue={!isEmpty(value)}
      width={width}
      hasErrors={hasErrors}
      showSelectAll={showSelectAll}
      maxMenuHeight={maxMenuHeight}
      closeMenuOnSelect={closeMenuOnSelect}
      //Define whether to search by default label and value or just label
      filterOption={filterByLabel ? labelFilter : undefined}
      // component to display before selected value in Control
      ControlPrefix={ControlPrefix}
      dataCy={dataCy}
      menuIsOpen={menuIsOpen}
      capitalizePlaceholder={capitalizePlaceholder}
    />
  );
};

export default Select;
