import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useFiles } from 'features/media/useFiles';
import { componentLoadingToggled } from 'features/ui/ui.slice';
import {
  addressAccessRejected,
  addressLoaded,
  addressUpdated,
  getUser,
  instagramDisconnected,
  phoneUpdated,
  profilePictureRemoved,
  tiktokDisconnected,
  userAccessRejected,
  userChanged,
  userCleared,
  userLoaded,
  userTagsUpdated,
} from 'features/users/hooks/user.slice';
import { CreatorOnboardingPayload } from 'features/users/pages/Onboarding/types';

import { apiAction } from 'shared/actions/api';
import { BRKFST_SUPPORTED_IMAGE_FORMATS } from 'shared/config/fileFormats';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_USER_ROUTES } from 'shared/config/routes/api/apiUserRoutes';
import { PhoneStatus } from 'shared/config/status/phone';
import { USER_TOASTS } from 'shared/config/toasts/userToasts';
import { GTM_USER_TYPES } from 'shared/config/types';
import { noOp } from 'shared/defaults';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { SIGNALR_NOTIFICATION_TIME_SPAN } from 'shared/hooks/useFileNotification';
import Messaging from 'shared/messaging/Messaging';
import { ApiOnSuccess } from 'shared/typings/api';
import { BrkfstUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { pushGtmOnboardEvent } from 'shared/utilities/trackingUtility';
import { GeneralValidator } from 'shared/utilities/validator';

export const useUser = (userId?: number) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user: BrkfstUser = useSelector(getUser);
  const { currentUser } = useCurrentUser();
  const { initiateSingleFileUpload } = useFiles();
  const isUserCreator = user.type === UserType.CREATOR;
  const { loading } = useComponentLoading(RESOURCES.USER);

  const { loading: loadingProfilePicture } = useComponentLoading(RESOURCES.PROFILE_PICTURE);
  const { loading: loadingAddress } = useComponentLoading(RESOURCES.ADDRESS);
  const { loading: loadingOnboarding } = useComponentLoading(RESOURCES.ONBOARDING_USER, false);
  const { loading: disconnectingInstagram } = useComponentLoading(RESOURCES.DISCONNECTING_IG, false);
  const { loading: disconnectingTiktok } = useComponentLoading(RESOURCES.DISCONNECTING_TIKTOK, false);
  const { loading: loadingPhone } = useComponentLoading(RESOURCES.USER_PHONE, false);
  const { loading: loadingUpdateUser } = useComponentLoading(RESOURCES.UPDATE_USER, false);
  const { loading: loadingNotification } = useComponentLoading(RESOURCES.USER_NOTIFICATION, false);

  const isCurrentUser = useMemo(() => {
    if (userId) {
      return userId === currentUser.id;
    }
    return user.id === currentUser.id;
  }, [userId, user.id, currentUser.id]);

  const creatorTags = useMemo(() => {
    const internalTags = user.internalTags || [];
    const creatorTags = user.creatorTags || [];
    const tags = internalTags.concat(creatorTags);
    if (user.type === UserType.CREATOR && user.height)
      tags.push({
        category: 'height',
        isOther: false,
        isStandard: true,
        type: 'users',
        value: user.height,
      });
    return tags;
  }, [user.internalTags, user.creatorTags]);

  const fetchUser = (id: number, organizationId?: number) => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.USER,
          variables: {
            userId: id,
          },
          query: {
            // needed for access to internal data on creators
            organizationId,
          },
        },
        successAction: userLoaded,
        errorAction: userAccessRejected,
        entity: [RESOURCES.USER, RESOURCES.PROFILE_PICTURE],
      }),
    );
  };

  const clearUser = () => {
    dispatch(userCleared({}));
    dispatch(componentLoadingToggled({ component: RESOURCES.USER, loading: true }));
  };

  const updateUser = ({
    data,
    successToast = {
      message: USER_TOASTS.UPDATED_USER_FIELD,
    },
    onSuccess,
  }: {
    data: any;
    successToast?: {
      message: string;
    };
    onSuccess?: ApiOnSuccess;
  }) => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.USER,
          variables: {
            userId,
          },
        },
        method: 'PATCH',
        data,
        successAction: userChanged,
        successToast,
        onSuccess,
        entity: RESOURCES.USER,
      }),
    );
  };

  const saveProfilePicture = async (newPic) => {
    dispatch(
      componentLoadingToggled({
        component: RESOURCES.PROFILE_PICTURE,
        loading: true,
      }),
    );
    await initiateSingleFileUpload(
      {
        file: newPic.file,
        metadata: {
          // @ts-ignore
          isAvatar: true.toString(),
        },
        dispatchLoader: true,
      },
      new GeneralValidator(BRKFST_SUPPORTED_IMAGE_FORMATS),
    );
    const { url } = newPic;

    setTimeout(() => {
      dispatch(
        componentLoadingToggled({
          component: RESOURCES.PROFILE_PICTURE,
          loading: false,
        }),
      );
    }, SIGNALR_NOTIFICATION_TIME_SPAN);

    await Messaging.refreshUser({
      id: currentUser.id,
      name: currentUser.fullName,
      email: currentUser.email,
      streamToken: currentUser.streamToken,
      phone: currentUser.phone,
      profilePic: { url },
      type: currentUser.type,
    });
  };

  const removeProfilePicture = () => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.PROFILE_PICTURE,
          variables: {
            userId,
          },
        },
        method: 'DELETE',
        data: { id: userId },
        successAction: profilePictureRemoved,
        entity: RESOURCES.PROFILE_PICTURE,
        successToast: {
          message: USER_TOASTS.PICTURE_REMOVED,
        },
        onSuccess: async (payload) => {
          await Messaging.refreshUser({
            id: currentUser.id,
            name: currentUser.fullName,
            email: currentUser.email,
            streamToken: currentUser.streamToken,
            phone: currentUser.phone,
            profilePic: { url: '' },
            type: currentUser.type,
          });
        },
      }),
    );
  };

  const updateUserTags = ({ creatorTags }) => {
    // any tags that don't already exist are created in updating the user
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.USER,
          variables: {
            userId,
          },
        },
        method: 'PATCH',
        data: { id: userId, creatorTags },
        successAction: userTagsUpdated,
      }),
    );
  };
  const setUserType = ({ type, pushFunction, id }) => {
    // can only do this once when the user is setting up their account
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.SET_USER_TYPE,
          variables: {
            userId: id,
          },
        },
        method: 'PATCH',
        data: { id, type },
        successAction: userChanged,
        entity: RESOURCES.USER,
        navigate,
        pushFunction,
      }),
    );
  };

  const getUserAddress = (id) => {
    // can only be accessed by the user themselves or
    // brand users under accounts that have approved the user
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.USER_ADDRESS,
          variables: {
            userId: id,
          },
        },
        successAction: addressLoaded,
        errorAction: addressAccessRejected,
        entity: RESOURCES.ADDRESS,
        hideError: true,
      }),
    );
  };

  const updateUserAddress = ({
    mailingAddress,
    onSuccess,
    showToast = true,
  }: {
    mailingAddress: any;
    onSuccess?: () => void;
    showToast?: boolean;
  }) => {
    // can only be accessed by the user themselves
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.USER_ADDRESS,
          variables: {
            userId,
          },
        },
        method: 'POST',
        data: mailingAddress,
        successAction: addressUpdated,
        successToast: showToast
          ? {
              message: USER_TOASTS.USER_ADDRESS_UPDATED,
            }
          : null,
        entity: RESOURCES.ADDRESS,
        ...(onSuccess ? { onSuccess } : {}),
        onError: () => null,
      }),
    );
  };

  const onboardCreator = (
    { address, notifications, tags, ...optionals }: CreatorOnboardingPayload,
    clearStorage: () => void,
  ) => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.ONBOARD_CREATOR,
          variables: {
            userId: currentUser.id,
          },
        },
        entity: [RESOURCES.ONBOARDING_USER, RESOURCES.USER],
        method: 'POST',
        data: {
          // Always Required
          address,
          notifications,
          tags,
          // Optional
          ...optionals,
        },
        successAction: userChanged,
        onSuccess: () => {
          clearStorage();
          pushGtmOnboardEvent(GTM_USER_TYPES.CREATOR);
          navigate(
            LinkCreator.createLink({
              routeKey: 'ONBOARD_COMPLETE',
              userType: UserType.CREATOR,
            }),
          );
        },
        successToast: notifications
          ? {
              message: USER_TOASTS.NOTIFICATIONS_TURNED_ON,
            }
          : null,
      }),
    );
  };

  const disconnectInstagram = () => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.DISCONNECT_INSTAGRAM,
          variables: {
            userId,
          },
        },
        method: 'DELETE',
        data: { id: userId },
        successAction: instagramDisconnected,
        entity: RESOURCES.DISCONNECTING_IG,
      }),
    );
  };

  const disconnectTiktok = () => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.DISCONNECT_TIKTOK,
          variables: {
            userId,
          },
        },
        method: 'DELETE',
        data: { id: userId },
        successAction: tiktokDisconnected,
        entity: RESOURCES.DISCONNECTING_TIKTOK,
      }),
    );
  };

  const createOrUpdatePhone = ({
    number,
    notifications,
    countryCode,
    showToast = true,
    onSuccess = () => noOp(),
    entity = RESOURCES.USER_PHONE,
  }) => {
    const getToastMessage = () => {
      const notificationsOn = user.phone?.status === PhoneStatus.ACTIVE;
      const notificationsChanged = notificationsOn !== notifications;

      const notificationMessage = notifications
        ? USER_TOASTS.NOTIFICATIONS_TURNED_ON
        : USER_TOASTS.NOTIFICATIONS_TURNED_OFF;

      const phoneUpdatedMessage = notifications
        ? USER_TOASTS.PHONE_UPDATED_WITH_NOTIFICATIONS
        : USER_TOASTS.PHONE_UPDATED;

      const toastMessage = notificationsChanged ? notificationMessage : phoneUpdatedMessage;
      return toastMessage;
    };
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.PHONE,
          variables: {
            userId,
          },
        },
        method: 'POST',
        data: { number, notifications, countryCode },
        successAction: phoneUpdated,
        successToast: showToast
          ? {
              message: getToastMessage(),
            }
          : null,
        entity: entity,
        onSuccess: async (payload) => {
          onSuccess();
          const { number: phoneNumber, status } = payload.data;
          await Messaging.updateUserPhone({
            phone: { number: phoneNumber, status },
            id: currentUser.id,
            name: currentUser.fullName,
            email: currentUser.email,
            streamToken: currentUser.streamToken,
            profilePic: {
              url: currentUser.profilePic?.url || '',
            },
            type: currentUser.type,
          });
        },
      }),
    );
  };

  const updateInternalCreatorData = (id, { tags, originalTags, note, originalNote }) => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.UPDATE_INTERNAL_DATA,
          variables: {
            userId: id,
          },
        },
        method: 'POST',
        data: {
          tags,
          originalTags,
          note,
          originalNote,
        },
        successToast: {
          message: USER_TOASTS.UPDATED_CREATOR_NOTES,
        },
        successAction: userChanged,
        errorAction: fetchUser(id),
      }),
    );
  };

  const deleteUser = (onSuccess) => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.DELETE_USER,
          variables: {
            userId,
          },
        },
        method: 'DELETE',
        data: { id: userId },
        onSuccess: () => onSuccess(),
      }),
    );
  };

  return {
    clearUser,
    createOrUpdatePhone,
    disconnectingInstagram,
    disconnectingTiktok,
    disconnectInstagram,
    disconnectTiktok,
    fetchUser,
    getUserAddress,
    isCurrentUser,
    isUserCreator,
    loading,
    loadingAddress,
    loadingOnboarding,
    loadingPhone,
    loadingProfilePicture,
    loadingUpdateUser,
    loadingNotification,
    onboardCreator,
    removeProfilePicture,
    saveProfilePicture,
    setUserType,
    updateInternalCreatorData,
    updateUser,
    updateUserAddress,
    updateUserTags,
    user,
    deleteUser,
    creatorTags,
  };
};
