import { faDollarSign, faEnvelope, faFilm, faMessage, faSuitcase, faUser } from '@fortawesome/pro-light-svg-icons';

import {
  AGE_FIELDNAMES,
  ETHNICITY_FIELDNAMES,
  ethnicityOtherOption,
  formatDependentDOB,
  GENDER_FIELDNAMES,
  genderOtherOption,
  LOCATION_FIELDNAMES,
  MODEL_AVAILABILITY_FIELDNAMES,
  NOTIFICATIONS_FIELDNAMES,
  PORTFOLIO_FIELDNAMES,
  WORK_PREFERENCES_FIELDNAMES,
} from 'features/users/components/CreatorOnboardingStepsFields';
import { HEIGHT_FIELDNAME } from 'features/users/components/CreatorOnboardingStepsFields/HeightFormFields';
import {
  LANGUAGE_FIELDNAMES,
  languageOtherOption,
} from 'features/users/components/CreatorOnboardingStepsFields/LanguageFormFields';

import { getSelectedValues } from '../Onboarding/CreatorOnboarding';
import { BrkfstTag } from 'shared/typings/tag';

export const sections = [
  { name: 'Portfolio', icon: faFilm },
  { name: 'Contact Info', icon: faEnvelope },
  { name: 'Profile Info', icon: faUser },
  { name: 'Work Info', icon: faSuitcase },
  { name: 'Payment', icon: faDollarSign },
  { name: 'Text Notifications', icon: faMessage },
];

export function formatAccountSettingsInfo(values, activeForm, creatorTags: BrkfstTag[] = []) {
  const gender = values[GENDER_FIELDNAMES.GENDER]?.find(({ selected }) => selected)?.value || '';
  const genderOther = values[GENDER_FIELDNAMES.GENDER_OTHER] || '';
  const ethnicityValues = getSelectedValues({
    data: values[ETHNICITY_FIELDNAMES.ETHNICITY],
    prefix: 'ethnicity',
    otherOption: ethnicityOtherOption,
    otherValue: values[ETHNICITY_FIELDNAMES.ETHNICITY_OTHER],
  });
  const languageValues = getSelectedValues({
    data: values[LANGUAGE_FIELDNAMES.LANGUAGE],
    prefix: 'language',
    otherOption: languageOtherOption,
    otherValue: values[LANGUAGE_FIELDNAMES.LANGUAGE_OTHER],
  });
  const modelValues = getSelectedValues({ data: values[MODEL_AVAILABILITY_FIELDNAMES.MODELS], prefix: 'model' });
  const locationValues = getSelectedValues({ data: values[LOCATION_FIELDNAMES.LOCATIONS], prefix: 'location' });
  const workTypeValues = getSelectedValues({
    data: values[WORK_PREFERENCES_FIELDNAMES.WORK_TYPE],
    prefix: 'work preference',
  });
  const editingValues = getSelectedValues({ data: values[WORK_PREFERENCES_FIELDNAMES.EDITING], prefix: 'editing' });

  const children = modelValues.find((value) => value === `model:${MODEL_AVAILABILITY_FIELDNAMES.CHILDREN}`)
    ? values[MODEL_AVAILABILITY_FIELDNAMES.CHILDREN]
    : [];

  const teenagers = modelValues.find((value) => value === `model:${MODEL_AVAILABILITY_FIELDNAMES.TEENAGERS}`)
    ? values[MODEL_AVAILABILITY_FIELDNAMES.TEENAGERS]
    : [];

  switch (activeForm) {
    case 'Portfolio':
      return {
        tiktok: values[PORTFOLIO_FIELDNAMES.TIKTOK_HANDLE],
        portfolioLink: values[PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK],
      };
    case 'Profile Info':
      let existingTags = creatorTags
        .filter(({ category }) => !['ethnicity', 'language'].includes(category))
        .map(({ value, category }) => `${category}:${value}`);
      return {
        birthdate: values[AGE_FIELDNAMES.DOB] ? new Date(values[AGE_FIELDNAMES.DOB]) : '',
        gender: gender === genderOtherOption.value ? genderOther : gender,
        height: values[HEIGHT_FIELDNAME]?.value,
        tags: [...existingTags, ...ethnicityValues, ...languageValues],
      };
    case 'Work Info':
      existingTags = creatorTags
        .filter(({ category }) => !['model', 'location', 'work preference', 'editing'].includes(category))
        .map(({ value, category }) => `${category}:${value}`);
      return {
        tags: [...existingTags, ...modelValues, ...locationValues, ...workTypeValues, ...editingValues],
        dependents: [...children, ...teenagers].map(({ dob }) => ({ birthdate: formatDependentDOB(dob) })),
      };
    case 'Text Notifications':
      return {
        notifications: values[NOTIFICATIONS_FIELDNAMES.OPT_IN],
      };
    default:
      return null;
  }
}
