import React, { useState } from 'react';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cs from 'classnames';

import { Box } from 'shared/components/display';

import getStyles from './newStyles';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'shared/styles/styleFunctions';

export enum TOOLTIP_SIZE {
  MAX_CONTENT = '100%',
  SMALL = '20%',
  MEDIUM = '30%',
  LARGE = '80%',
}

interface Props {
  children: React.ReactElement;
  content: React.ReactNode;
  disabled?: boolean;
  className?: string;
  portalTarget?: string | HTMLElement | null;
  maxWidth?: TOOLTIP_SIZE | number;
  placement?: Placement;
  onClick?: (e) => void;
  debug?: boolean;
}

/**
 *
 * @param children a single component that can accept a ref
 * @param content the content to display in the tooltip
 */
const Tooltip: React.FC<Props> = ({
  children,
  content,
  disabled,
  className,
  portalTarget = 'body',
  maxWidth,
  placement,
  onClick,
  debug = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (bool) => {
      setIsOpen(bool);
    },
    middleware: [offset(5), flip(), shift()],
    whileElementsMounted: autoUpdate,
    ...(placement ? { placement } : {}),
  });

  const hover = useHover(context, { move: false, enabled: !disabled && !isMobile });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });
  const click = useClick(context, { enabled: !disabled && isMobile, event: 'click' });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, hover, focus, dismiss, role]);

  const portalId = typeof portalTarget === 'string' ? portalTarget : undefined;
  const portalRoot = typeof portalTarget !== 'string' ? portalTarget : undefined;

  return (
    <>
      {React.cloneElement(
        children,
        getReferenceProps({
          ref: refs.setReference,
          ...children.props,
          onClick,
          'data-state': isOpen ? 'open' : 'closed',
        }),
      )}
      {(isOpen || debug) && (
        <FloatingPortal id={portalId} root={portalRoot}>
          <Box
            css={getStyles(maxWidth)}
            className={cs('brkfst-tooltip', className)}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {content}
          </Box>
        </FloatingPortal>
      )}
    </>
  );
};

export default Tooltip;
