import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { faBadgeCheck, faCrown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import ApprovalButtons from 'features/briefs/components/molecules/ApprovalButtons';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import CreatorTags from 'features/users/components/molecules/CreatorTags/CreatorTags';
import { ProfilePicture } from 'features/users/components/molecules/ProfilePicture';
import MessageButton from 'shared/components/atoms/MessageButton';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { Box, Flex, Text } from 'shared/components/display';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';

import { CREATOR_COPY, USER_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { CreatorUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
import { prettyDate } from 'shared/utilities/dateUtility';
import { LinkCreator } from 'shared/utilities/linkUtility';

import styles from './styles';

interface Props {
  creator: CreatorUser;
  accountId: number;
  briefId: number;
  loading?: boolean;
  className?: string;
}

const BriefCreatorInfo: React.FC<Props> = ({ creator, accountId, loading = false, briefId, className }) => {
  const { isSuperadmin } = useCurrentUser();
  const { account } = useAccounts({ accountId: accountId.toString() });
  const { brief } = useBriefs(briefId);

  const profileLink = LinkCreator.createLink({
    userType: UserType.CREATOR,
    routeKey: 'USER_PROFILE',
    variables: {
      userId: creator.id,
    },
  });

  const tags = useMemo(() => {
    return [...(creator.internalTags || []), ...(creator.creatorTags || [])];
  }, [creator.internalTags, creator.creatorTags]);

  if (loading) {
    return (
      <Box className={cs('brief-creator-info', className)} css={styles}>
        <CenteredSpinner />
      </Box>
    );
  }

  return (
    <Box className={cs('brief-creator-info', className)} css={styles}>
      {creator.creatorBriefStatus === UserBriefStatus.INVITED ? (
        <Text variant={TEXT_VARIANTS.H4} className="brief-creator-info__invited-creator">
          {CREATOR_COPY.PLACEHOLDER_INVITED_CREATOR_NOT_ACCEPTED}
        </Text>
      ) : (
        <>
          <Flex className="brief-creator-info__header">
            <Box className="brief-creator-info__name-pic-wrapper">
              <Box className="brief-creator-info__pic">
                <ProfilePicture user={creator} size="md" customProfileLink={profileLink} openNewTab />
                {/* TODO: replace with BrandApprovedBadge atom */}
                {creator.brandApprovedBadge && (
                  <Tooltip content="Brand Approved Badge">
                    <FontAwesomeIcon icon={faBadgeCheck} className="brief-creator-info__asset-badge" />
                  </Tooltip>
                )}
                {/* TODO: replace with EliteCreatorBadge atom */}
                {creator.isElite && (
                  <Tooltip content={CREATOR_COPY.TOOLTIP_ELITE_CREATOR}>
                    <FontAwesomeIcon icon={faCrown} className="brief-creator-info__elite-badge" />
                  </Tooltip>
                )}
              </Box>
              <Link
                className="brief-creator-info__creator-name"
                to={profileLink}
                target="_blank"
              >{`${creator.firstName} ${creator.lastName}`}</Link>
            </Box>
            <ApprovalButtons creator={creator} brief={brief} />
          </Flex>
          <DefinitionList labelColor={COLORS.NEUTRAL500}>
            <ListDetail
              detailsClassName="brief-creator-info__application-pitch"
              labelClassName="brief-creator-info__application-pitch-label"
              text={CREATOR_COPY.LABEL_CREATOR_MESSAGE}
              hide={!creator.applicationPitch?.length}
            >
              {creator.applicationPitch || ''}
            </ListDetail>
            <ListDetail text="Tags" hide={!tags.length} hideLabel>
              <CreatorTags tags={tags} theme="top-category" className="brief-creator-info__tags" />
            </ListDetail>
            <ListDetail text={CREATOR_COPY.COLUMN_CREATOR_APPLIED_DATE}>
              {creator.creatorBriefDateCreated ? prettyDate(creator.creatorBriefDateCreated) : '-'}
            </ListDetail>

            <ListDetail text={USER_COPY.LABEL_ACTIVE_BRIEFS} hide={!isSuperadmin}>
              {`${creator.inProgressBriefsCount || 0}`}
            </ListDetail>
            <ListDetail hide={!Boolean(creator.stateCodes?.length)} text="Able to Travel">
              {creator.stateCodes?.length ? creator.stateCodes.join(', ') : '-'}
            </ListDetail>
          </DefinitionList>
          <Flex className="brief-creator-info__footer">
            {(creator.creatorBriefStatus === UserBriefStatus.ACTIVE ||
              creator.creatorBriefStatus === UserBriefStatus.SUBMITTED ||
              creator.creatorBriefStatus === UserBriefStatus.DRAFTED) && (
              <MessageButton user={creator} account={account} variant={BUTTON_VARIANTS.MICRO} />
            )}
          </Flex>
        </>
      )}
    </Box>
  );
};

export default BriefCreatorInfo;
