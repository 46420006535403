import React, { Fragment, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { difference } from 'lodash';

import { useBriefs } from 'features/briefs/hooks/useBriefs';
import { useInvoice } from 'features/organizations/useInvoice';
import { Text } from 'shared/components/display';
import CheckoutPayment from 'shared/components/molecules/CheckoutPayment';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import CheckoutPageTemplate from 'shared/components/organisms/CheckoutPageTemplate';

import { BRIEF_COPY } from 'shared/config/copy';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import theme from 'shared/styles/themes';
import { BrkfstUser } from 'shared/typings/user';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

const BriefInviteApprovalCheckoutPage = () => {
  const params = useParams<{ organizationId: string; accountId: string; briefId: string; creatorId: string }>();
  const location = useLocation<{
    creatorBriefStatus: UserBriefStatus;
    creatorEmails: string[];
    briefName: string;
    creators?: BrkfstUser[];
  }>();
  const navigate = useNavigate();
  const { organizationId, accountId, briefId } = params;
  const { inviteCreators } = useBriefs(briefId);
  const { briefName, creatorBriefStatus, creatorEmails, creators } = location.state || {};

  const { createBriefInviteApprovalInvoice, checkoutInvoice } = useInvoice({
    accountId: +accountId,
    organizationId: +organizationId,
  });

  const pathToBrief = useMemo(
    () =>
      LinkCreator.createLink({
        routeKey: 'VIEW_INDIVIDUAL_BRIEF',
        variables: {
          accountId,
          organizationId,
          briefId,
        },
      }),
    [accountId, briefId, organizationId],
  );

  const successRedirectRoute = useMemo(
    () =>
      LinkCreator.createLink({
        routeKey: 'BRIEF_APPROVAL_CHECKOUT_CONFIRMATION',
        variables: {
          accountId,
          organizationId,
          briefId,
        },
      }),
    [accountId, briefId, organizationId],
  );

  const isInvited = useMemo(() => creatorBriefStatus === UserBriefStatus.INVITED, [creatorBriefStatus]);

  useEffect(() => {
    if (!isInvited) {
      navigate(pathToBrief);
    }
  }, [isInvited, pathToBrief, history]);

  useEffect(() => {
    if (isInvited) createBriefInviteApprovalInvoice({ briefId, creatorEmails, accountId });
  }, [briefId, creatorEmails, accountId, isInvited]);

  const confirmationPageProps = {
    body: BRIEF_COPY.CHECKOUT_SUCCESS_BODY_BRIEF_APPROVAL,
    buttonLink: pathToBrief,
    buttonText: BRIEF_COPY.CHECKOUT_SUCCESS_BUTTON_BRIEF,
  };

  const onSubmit = () => {
    // Invite Creator
    inviteCreators({ autoApprove: true, accountId, emails: creatorEmails, paymentIntentId: checkoutInvoice.id });
  };

  const newCreatorEmails = useMemo(() => {
    if (!creators?.length) return creatorEmails;
    const existingCreatorEmails = creators.map(({ email }) => email);
    return difference(creatorEmails, existingCreatorEmails);
  }, [creators, creatorEmails]);

  const existingCreatorNames = useMemo(() => {
    if (!creators?.length) return [];
    return creators.map(({ fullName }) => fullName);
  }, [creators]);

  return (
    <CheckoutPageTemplate
      backButtonProps={{
        text: BRIEF_COPY.BUTTON_BACK_TO_BRIEF,
        to: pathToBrief,
        state: location.state,
      }}
      CheckoutComponent={
        <Fragment>
          <Text
            variant={TEXT_VARIANTS.H4}
            style={{
              marginBottom: theme.space[4],
            }}
          >
            {BRIEF_COPY.HEADING_APPROVE_CREATOR}
          </Text>
          <Text variant={TEXT_VARIANTS.H4}>{BRIEF_COPY.HEADING_DETAILS}</Text>
          <DefinitionList labelColor={COLORS.BLACK}>
            <ListDetail text="Brief">{briefName}</ListDetail>
            <ListDetail hide={!newCreatorEmails.length} text="New Creators">
              {newCreatorEmails.join(', ')}
            </ListDetail>
            <ListDetail hide={!existingCreatorNames.length} text="Creators">
              {existingCreatorNames.join(', ')}
            </ListDetail>
          </DefinitionList>
          <CheckoutPayment />
        </Fragment>
      }
      onCheckoutSuccess={onSubmit}
      onSubmitText={BRIEF_COPY.BUTTON_CONFIRM_CREATOR_APPROVAL}
      heading={BRIEF_COPY.HEADING_PAYMENT_SUMMARY}
      successRedirectRoute={successRedirectRoute}
      confirmationPageProps={confirmationPageProps}
      leaveProcessingPageMessage={BRIEF_COPY.CHECKOUT_CONFIRM_LEAVE_PROCESSING_BRIEF}
    />
  );
};

export default BriefInviteApprovalCheckoutPage;
