import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import cs from 'classnames';

import CenteredSpinner from 'features/ui/CenteredSpinner';
import { CreativeSafeZone } from 'shared/components/atoms/CreativeSafeZone';
import { Flex, Text } from 'shared/components/display';
import { SafeZoneButtons, SafeZoneButtonsProps } from 'shared/components/molecules/SafeZoneButtons';

import { FILE_COPY } from 'shared/config/copy';
import { MEDIA } from 'shared/config/media';
import { VIDEO_PREFETCH_SIZE_LIMIT } from 'shared/config/validations';
import { breakpoints } from 'shared/styles/styleFunctions';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstFile } from 'shared/typings/file';
import { getMimeType } from 'shared/utilities/fileUtility';

import videoPlayerStyles from './videoPlayerStyles';

const getVideoType = (src: string, extension?: string) => {
  const srcQueryStartIndex = src.indexOf('?');
  const srcWithoutQuery = srcQueryStartIndex > 0 ? src.slice(0, srcQueryStartIndex) : src;
  const videoType = extension?.slice(1) || srcWithoutQuery.slice(srcWithoutQuery.lastIndexOf('.') + 1);
  return videoType;
};

interface Props {
  className?: string;
  stopPropagation?: boolean;
  thumbnailUrl?: string;
  isActive: boolean;
  is9By16?: boolean;
}

const VideoPlayer: React.FC<
  Props & SafeZoneButtonsProps & Pick<BrkfstFile, 'url' | 'mimeType' | 'extension' | 'metadata'>
> = ({
  url,
  mimeType,
  extension,
  metadata: { size, height },
  className,
  stopPropagation = true,
  thumbnailUrl,
  isActive,
  showFbSafezone = false,
  showTtSafezone = false,
  onFbClick,
  onTtClick,
  is9By16 = false,
}) => {
  const [videoBlob, setVideoBlob] = useState('');
  const videoRef = useRef<HTMLVideoElement>(null);

  const onClick = (e: SyntheticEvent) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
  };
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  useEffect(() => {
    if (!isActive) {
      if (videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
      }
    }
  }, [isActive]);

  if (!url) {
    return (
      <Flex css={videoPlayerStyles} className={cs('video-player', className)}>
        <Text variant={TEXT_VARIANTS.H3} className="video-player__placeholder-text">
          {FILE_COPY.PLACEHOLDER_FILE_BEING_PROCESSED}
        </Text>
      </Flex>
    );
  }

  useEffect(() => {
    if (mimeType.toLowerCase().includes(MEDIA.TYPES.VIDEO)) {
      if (size && size < VIDEO_PREFETCH_SIZE_LIMIT) {
        const req = new XMLHttpRequest();
        req.open('GET', url, true);
        req.responseType = 'blob';

        req.onload = () => {
          const { status, response } = req;
          if (status === 200) {
            const vid = URL.createObjectURL(response);
            setVideoBlob(vid);
          }
        };
        req.onerror = () => setVideoBlob(url);
        req.send();
      } else {
        setVideoBlob(url);
      }
    }
  }, [url, mimeType, size]);

  const videoType = getVideoType(url, extension);
  // Tricks browsers that don't support .mov files to play as mp4.
  const videoMimeType =
    videoType.toLowerCase() === MEDIA.FILE_TYPES.MOV ? getMimeType(MEDIA.FILE_TYPES.MP4) : getMimeType(videoType);

  return videoBlob ? (
    <Flex css={videoPlayerStyles} className={cs('video-player', className)} onClick={onClick}>
      <>
        <video
          controls
          disablePictureInPicture
          controlsList="nodownload"
          className="video-player__video"
          {...(thumbnailUrl && isMobile
            ? {
                poster: thumbnailUrl,
              }
            : { thumbnailurl: thumbnailUrl })}
          ref={videoRef}
          height={height}
          key={thumbnailUrl}
        >
          <source type={videoMimeType} src={videoBlob} />
          Your browser does not support the video tag.
        </video>
        {is9By16 && <CreativeSafeZone showFbSafezone={showFbSafezone} showTtSafezone={showTtSafezone} />}
        {is9By16 && (
          <SafeZoneButtons
            showFbSafezone={showFbSafezone}
            showTtSafezone={showTtSafezone}
            onFbClick={onFbClick}
            onTtClick={onTtClick}
          />
        )}
      </>
    </Flex>
  ) : (
    <CenteredSpinner />
  );
};

export default VideoPlayer;
