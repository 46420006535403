import React, { useMemo } from 'react';
import cs from 'classnames';

import { Flex } from 'shared/components/display';
import CategoryTags from 'shared/components/molecules/CategoryTags';

import { BrkfstTag } from 'shared/typings/tag';

import styles from './styles';

interface Props {
  tags: BrkfstTag[];
  className?: string;
  theme?: 'top-category' | 'left-category';
}
const CreatorTags: React.FC<Props> = ({ tags, className, theme = 'left-category' }) => {
  const formattedTags: Record<string, { value: string; internal: boolean }[]> = useMemo(() => {
    return tags.reduce((acc, tag) => {
      const category = tag.category;
      if (tag.isStandard) {
        if (!acc[category]) {
          acc[category] = [
            {
              value: tag.isOther ? 'other' : tag.value,
              internal: tag.internal,
            },
          ];
        } else {
          acc[category].push({ value: tag.isOther ? 'other' : tag.value, internal: tag.internal });
        }
      }

      return acc;
    }, {});
  }, [tags]);

  return (
    <Flex css={styles} className={cs('creator-tags', `creator-tags--${theme}`, className)}>
      {Object.keys(formattedTags).map((category) => (
        <CategoryTags
          key={category}
          category={category}
          values={formattedTags[category]}
          className="creator-tags__category-group"
          theme={theme}
        />
      ))}
    </Flex>
  );
};

export default CreatorTags;
