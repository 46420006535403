import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import { Field, useFormikContext } from 'formik';

import { Box, Flex } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import ButtonInput from 'shared/components/molecules/ButtonInput/ButtonInput';
import TextInput from 'shared/components/TextInput';

import { makeArray } from 'shared/lib/formik';
import { BUTTON_VARIANTS } from 'shared/styles/button';

import styles from './styles';

export interface Option {
  label: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
}

interface Props {
  disabled?: boolean;
  otherOptionValue?: string;
  otherOptionFieldName?: string;
  isMultiSelect?: boolean;
  dataCy?: string;
  variant?: string;
  className?: string;
}

const ButtonGroupInput = makeArray<Option, Props>(
  ({
    values,
    name,
    disabled,
    otherOptionValue,
    otherOptionFieldName,
    isMultiSelect = false,
    variant = BUTTON_VARIANTS.TEXT,
    className,
    dataCy = 'btn-group',
  }) => {
    const { setFieldValue } = useFormikContext<any>();
    const [showTextField, setShowTextField] = useState(
      values?.find((v) => otherOptionValue === v.value)?.selected || false,
    );
    const onChange = (value: string, selected: boolean) => {
      if (!isMultiSelect && selected) {
        values?.forEach((option) => (option.selected = value === option.value));
        setFieldValue(name, values);
        setShowTextField(value === otherOptionValue);
      } else if (value === otherOptionValue) setShowTextField(selected);
    };

    useEffect(() => {
      setShowTextField(values?.find((v) => otherOptionValue === v.value)?.selected || false);
    }, [values]);

    return (
      <Box css={styles} className={cs('btn-group', className)}>
        <Flex className={'btn-group__options'}>
          {values?.map((option, index) => {
            const { label, disabled: optionDisabled } = option;
            return (
              <Field
                name={`${name}.[${index}]`}
                component={ButtonInput}
                disabled={optionDisabled || disabled}
                variant={variant}
                children={label}
                onChange={onChange}
                key={index}
                dataCy={dataCy}
                showError={false}
              />
            );
          })}
          {showTextField && (
            <Field
              name={otherOptionFieldName}
              label="Other"
              component={TextInput}
              className="btn-group__other"
              maxLength={20}
              hidePlaceholder
              dataCy={`${dataCy}__other`}
            />
          )}
        </Flex>
        <FieldErrorMessage name={name} className="btn-group__error" dataCy={`${dataCy}__error`} />
      </Box>
    );
  },
);

export default ButtonGroupInput;
