import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import cs from 'classnames';

import TrendCopyLink from 'features/trends/components/molecules/TrendCopyLink';
import TrendEllipsisMenu from 'features/trends/components/molecules/TrendEllipsisMenu';
import TrendGallery from 'features/trends/components/organisms/TrendGallery';
import { useTrendFileNotifications } from 'features/trends/hooks/useTrendFileNotifications';
import { useTrends } from 'features/trends/hooks/useTrends';
import { Flex, Text } from 'shared/components/display';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

const TrendsLibrary: React.FC = () => {
  const { trends, fetchTrends, loading, loadingNewTrend, loadingPinTrend } = useTrends();
  const { isSuperadmin } = useCurrentUser();
  const { accountId } = useParams<{ accountId: string }>();
  useTrendFileNotifications();

  useEffect(() => {
    fetchTrends({
      page: 0,
      size: 50, // TODO: BRKFST-500 add pagination
      accountId,
    });
    // refetch trends when a trend is pinned/unpinned
  }, [accountId, loadingPinTrend]);

  return (
    <Flex css={styles} className={cs('trends-library')}>
      <Text as="h1" variant={TEXT_VARIANTS.H1} className="trends-library__header">
        <FormattedMessage
          id="TREND_LIBRARY_HEADER"
          values={{
            pink: (chunks) => <span className="trends-library__text--pink">{chunks}</span>,
          }}
        />
      </Text>
      <Flex className="trends-library__control-bar">
        {isSuperadmin && <TrendEllipsisMenu showNewButton />}
        <TrendCopyLink />
      </Flex>
      <TrendGallery items={trends} loading={loading} loadingNew={loadingNewTrend} accountId={+accountId} />
    </Flex>
  );
};

export default TrendsLibrary;
