export const CREATOR_ACCOUNT_SETTINGS_COPY = {
  SUBSECTION_TITLE_GENDER: 'What is your gender?',
  SUBSECTION_TITLE_AGE: 'How old are you?',
  SUBSECTION_TITLE_HEIGHT: 'What is your height?',
  SUBSECTION_TITLE_ETHNICITY: 'What is your ethnicity?',
  SUBSECTION_TITLE_LANGUAGE: 'What languages do you speak?',
  SUBSECTION_TITLE_LOCATION: 'Can you film in any of these locations?',
  SUBSECTION_TITLE_MODELS: 'Can you film any of these models?',
  SUBSECTION_TITLE_WORK_PREF: 'What type of work do you prefer?',
};
