import React from 'react';
import ReactModal from 'react-modal';
import { Button } from 'rebass/styled-components';
import cs from 'classnames';

import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Box, Flex, Text } from 'shared/components/display';
import modalStyles, { reactModalCss } from 'shared/components/molecules/Modal/modalStyles';

import { SIZING } from 'shared/config/formatting';
import { BUTTON_VARIANTS } from 'shared/styles/button';

const { MEDIUM } = SIZING;
const { TEXT_ONLY } = BUTTON_VARIANTS;

/**
 * https://www.w3.org/TR/wai-aria-practices/#dialog_modal
 * The alertdialog role is a special-case dialog role designed specifically for dialogs that divert users' attention to a brief, important message.
 * Otherwise, use dialog
 */
type ModalAriaRole = 'dialog' | 'alertdialog';
interface Props {
  onRequestClose?: (event?: any) => void | (() => any);
  component?: React.ReactNode;
  title: string;
  footer?: any;
  modalSize?: string;
  isLoading?: boolean;
  ariaRole?: ModalAriaRole;
  // if no title provided, provide aria label to tell screen readers what the modal is
  ariaLabel?: string;
  isOpen: boolean;
  transparentBackground?: boolean;
  Header?: React.ReactElement<{ CloseButton: React.ReactElement }, string | React.JSXElementConstructor<any>>;
}

const Modal: React.FC<Props> = ({
  onRequestClose,
  component,
  title,
  footer,
  modalSize = MEDIUM,
  isLoading = false,
  ariaRole = 'dialog',
  ariaLabel = '',
  isOpen,
  transparentBackground = false,
  Header,
}) => {
  // used to hide the application from screenreaders and other assistive technologies while the modal is open
  // the aria-hidden attribute will be applied to .App div rather than document body
  ReactModal.setAppElement('#root');
  const requestToClose = (e) => {
    if (!isLoading && onRequestClose) {
      onRequestClose(e);
    }
  };
  const calculateModalAriaLabel = () => {
    // https://developer.yoast.com/blog/the-a11y-monthly-making-modals-accessible/
    if (title) {
      /**
       * Used when there is a visible title, indicates the header element associated with the content it heads
       */
      return {
        labelledby: 'modal-title',
      };
    }
    /**
     * if the modal doesn't have a visible title, use the ARIA property aria-label with some meaningful text on the element that has role=dialog
     */
    return {
      label: ariaLabel,
    };
  };
  const contentClassName = 'brkfst-modal__content-wrapper';

  return (
    <ReactModal
      onRequestClose={requestToClose}
      isOpen={isOpen}
      role={ariaRole}
      aria={calculateModalAriaLabel()}
      style={reactModalCss}
      overlayClassName="brkfst-modal__click-overlay"
      className="brkfst-modal"
      id="modal-root" // used by Select and MultiSelect to portal the menu element in order to make it visible outside of the modal. Open issue with library: https://github.com/JedWatson/react-select/issues/4088
    >
      <Box css={modalStyles}>
        <Flex
          className={cs({
            [contentClassName]: true,
            [`${contentClassName}--${modalSize}`]: true,
            [`${contentClassName}--${modalSize}--with-title`]: !!title,
            [`${contentClassName}--transparent`]: transparentBackground,
          })}
        >
          {isLoading && (
            <Flex className="brkfst-modal__loading-container">
              <CenteredSpinner className="brkfst-modal__loading-spinner" />
            </Flex>
          )}
          {Header ? (
            React.cloneElement(Header, {
              CloseButton: (
                <Button variant={TEXT_ONLY} onClick={requestToClose} className="brkfst-modal__header-btn" type="button">
                  &#10005;
                </Button>
              ),
            })
          ) : (
            <Box
              className={cs('brkfst-modal__header', {
                ['brkfst-modal__header--with-title']: !!title,
                [`brkfst-modal__header--transparent`]: transparentBackground,
              })}
            >
              {title && (
                <Text
                  as="h4"
                  className={cs(
                    'brkfst-modal__header-text',
                    'endWithEllipsis',
                    `brkfst-modal__header-text--${modalSize}`,
                  )}
                  id="modal-title"
                >
                  {title}
                </Text>
              )}
              <Button variant={TEXT_ONLY} onClick={requestToClose} className="brkfst-modal__header-btn" type="button">
                &#10005;
              </Button>
            </Box>
          )}

          <Box id="brkfst-modal-content" className="brkfst-modal__component-wrapper">
            {component}
          </Box>
          {!!footer && <Flex className="brkfst-modal__footer-wrapper">{footer}</Flex>}
        </Flex>
      </Box>
    </ReactModal>
  );
};

export default Modal;
