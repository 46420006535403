import { VettingStatus } from 'shared/typings/user/enums';

export enum SearchBarFields {
  Search = 'search',
  RecentlyActive = 'recentlyActive',
  VettingStatus = 'vettingStatus',
  Experience = 'experience',
  Gender = 'gender',
  Industry = 'industry',
  Niche = 'niche',
  Location = 'location',
  Model = 'model',
  Ethnicity = 'ethnicity',
  Language = 'language',
  AgeTags = 'ageTags',
  AgeRange = 'ageRange',
  Height = 'height',
}

export type SearchBarState = {
  search: string;
  recentlyActive: FilterOption<RecentlyActiveValue>[];
  vettingStatus: FilterOption<VettingStatus>[];
  experience: FilterOption<ExperienceValue>[];
  gender: FilterOption<GenderValue>[];
  industry: FilterOption<string>[];
  niche: FilterOption<string>[];
  location: FilterOption<string>[];
  model: FilterOption<string>[];
  ethnicity: FilterOption<string>[];
  language: FilterOption<string>[];
  ageTags: FilterOption<string>[];
  ageRange: number[];
  height: number[];
};

export type ExperienceValue = 'approvedAsset' | 'eliteCreator';

export type RecentlyActiveValue = '60 days' | '6 months' | '';

export type GenderValue = 'male' | 'female' | 'non-binary' | 'other';
export interface FilterOption<Value = string> {
  label: string;
  value: Value;
  selected: boolean;
}

// See awpl-api src/API/controllers/user/types.ts
export type GetAllUsersQuery = {
  experience?: ExperienceValue[];
  recentlyActive?: RecentlyActiveValue;
  vettingStatus?: VettingStatus;
  gender?: GenderValue[];
  search?: string;
  page?: number;
  size?: number;
  ageRange?: {
    min: number;
    max: number;
  };
  height?: {
    min: number;
    max: number;
  };
  tags?: Record<string, string[]>;
  models?: { children: boolean; teenagers: boolean };
};
