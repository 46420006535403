import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import { fontSizes } from 'shared/styles/text';

export default css({
  '&.portfolio-form': {
    [mediaquery.md]: { paddingLeft: '8px' },
  },
  '.portfolio-form': {
    '&__ig-wrapper': {
      paddingBottom: '10px',
      width: '100%',
      [mediaquery.md]: {
        maxWidth: '460px',
      },
    },
    '&__label, &__ig-label': {
      paddingTop: '10px',
      marginBottom: '0',
    },
    '&__optional': {
      paddingTop: '10px',
      marginBottom: '0',
      fontSize: '1rem',
      color: COLORS.NEUTRAL500,
    },
    '&__ig-header': {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '&__btns-wrapper': {
      width: '100%',
      justifyContent: 'end',
      paddingTop: '15px',
    },
    '&__cancel-btn': {
      width: '100px',
      marginRight: '10px',
      backgroundColor: COLORS.NEUTRAL200,
      color: COLORS.NEUTRAL700,
      cursor: 'pointer',
    },
    '&__connect-ig-wrapper': {
      padding: '10px 10px',
      marginTop: '25px',
      backgroundColor: COLORS.ORANGE50,
      alignItems: 'flex-start',
      marginBottom: '20px',
      borderRadius: '4px',
    },
    '&__connect-ig-text': {
      color: COLORS.ORANGE800,
      margin: '0',
      lineHeight: '1.5',
      fontSize: fontSizes[1],
    },
    '&__exclamation-icon': {
      fontSize: '22px',
      padding: '5px 15px 0 10px',
    },
    '&__connect-ig-benefits-text': {
      lineHeight: '1.5',
    },
  },
});
