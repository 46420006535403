import React, { useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'rebass';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { capitalize } from 'lodash';
import * as yup from 'yup';

import { CREATOR_ACCOUNT_SETTINGS_COPY } from 'features/users/pages/CreatorAccountSettings/copy';
import { Box, Flex, Text } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import CategoryTags from 'shared/components/molecules/CategoryTags';

import { makeLabel } from 'shared/lib/formik';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { breakpoints } from 'shared/styles/styleFunctions';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser, CreatorUser } from 'shared/typings/user';

import {
  LOCATION_FIELDNAMES,
  LocationFormFields,
  locationSchema,
  MODEL_AVAILABILITY_FIELDNAMES,
  ModelAvailabilityFormFields,
  modelSchema,
  WORK_PREFERENCES_FIELDNAMES,
  WorkPreferencesFormFields,
  workPreferencesSchema,
} from '../../CreatorOnboardingStepsFields';

import { FIELDNAMES, formatDependentDateOfBirth, getFieldsToReset, getInitialValues, WorkInfoSections } from './config';
import styles from './styles';

interface Props {
  user: BrkfstUser;
  tags: { [category: string]: { label: string; value: string }[] };
  onSubmit: (values: any) => void;
  onNavigateBack?: () => void;
}

const WorkInfoForm: React.FC<Props> = ({ user, tags, onSubmit, onNavigateBack }) => {
  const [activeForm, setActiveForm] = useState<null | WorkInfoSections>(null);
  const creatorUser = user as CreatorUser;
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  const initialValues = getInitialValues(creatorUser, tags);

  const onCancel = (setValues: (values: any) => void, currentValues: any) => {
    let fieldsToReset = getFieldsToReset(activeForm, initialValues);

    setValues({ ...currentValues, ...fieldsToReset });
  };

  const onGoBack = (setValues: (values: any) => void, currentValues: any) => {
    onCancel(setValues, currentValues);
    setActiveForm(null);
  };

  const mapDependents = (dependents) => {
    return dependents.map(({ dob }) => {
      const existingDependent = creatorUser.dependents?.find(
        (dependent) => formatDependentDateOfBirth(dependent.birthdate) === dob,
      );
      // Include the id for dependent updates in the be
      return existingDependent
        ? { ...existingDependent, dob: formatDependentDateOfBirth(existingDependent.birthdate) }
        : { dob };
    });
  };

  const handleSubmit = (values: any) => {
    onSubmit({
      ...values,
      [MODEL_AVAILABILITY_FIELDNAMES.CHILDREN]: mapDependents(values[MODEL_AVAILABILITY_FIELDNAMES.CHILDREN]),
      [MODEL_AVAILABILITY_FIELDNAMES.TEENAGERS]: mapDependents(values[MODEL_AVAILABILITY_FIELDNAMES.TEENAGERS]),
    });
  };

  const getFormDisplayValue = (formName: WorkInfoSections, values) => {
    switch (formName) {
      case FIELDNAMES.WORK_PREFERENCES:
        const selectedWorkPreferences = [
          ...values[WORK_PREFERENCES_FIELDNAMES.EDITING],
          ...values[WORK_PREFERENCES_FIELDNAMES.WORK_TYPE],
        ].filter(({ selected }) => selected);
        return selectedWorkPreferences?.length > 0 ? (
          <CategoryTags
            category="work preference"
            theme="definition-list-category"
            className="work-info-form__display-tags"
            values={selectedWorkPreferences}
            uppercaseCategory
          />
        ) : (
          <Box>
            <Text variant={TEXT_VARIANTS.SUBHEADING_SM} color={COLORS.NEUTRAL400} className="work-info-form__btn-name">
              {makeLabel(`${FIELDNAMES.WORK_PREFERENCES}`)}
            </Text>
            <Text className="work-info-form__btn-value">-</Text>
          </Box>
        );
      case FIELDNAMES.MODELS:
        const models = [
          ...creatorUser.creatorTags
            ?.filter((tags) => tags.category === 'model')
            .map((tag) => ({ label: capitalize(tag.value), value: tag.value })),
        ];
        return models?.length > 0 ? (
          <CategoryTags
            category="model"
            theme="definition-list-category"
            className="work-form__display-tags"
            values={models}
            duplicates
            uppercaseCategory
          />
        ) : (
          <Box>
            <Text variant={TEXT_VARIANTS.SUBHEADING_SM} color={COLORS.NEUTRAL400} className="work-info-form__btn-name">
              {makeLabel(`${FIELDNAMES.MODELS}`)}
            </Text>
            <Text className="work-info-form__btn-value">-</Text>
          </Box>
        );
      case FIELDNAMES.LOCATIONS:
        const locations = values[LOCATION_FIELDNAMES.LOCATIONS]?.filter(({ selected }) => selected);
        return locations?.length > 0 ? (
          <CategoryTags
            category="location"
            theme="definition-list-category"
            className="work-info-form__display-tags"
            values={locations}
            uppercaseCategory
          />
        ) : (
          <Box>
            <Text variant={TEXT_VARIANTS.SUBHEADING_SM} color={COLORS.NEUTRAL400} className="work-info-form__btn-name">
              {makeLabel(`${FIELDNAMES.LOCATIONS}`)}
            </Text>
            <Text className="work-info-form__btn-value">-</Text>
          </Box>
        );
    }
  };

  const validationSchema = useMemo(() => {
    const schemas = {
      [FIELDNAMES.WORK_PREFERENCES]: workPreferencesSchema,
      [FIELDNAMES.MODELS]: modelSchema,
      [FIELDNAMES.LOCATIONS]: locationSchema,
    };

    return activeForm ? schemas[activeForm] : yup.object();
  }, [activeForm]);

  return (
    <Box css={styles}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ setValues, values }) => (
          <Form
            className={cs({
              'work-info-form__form--active': Boolean(activeForm),
            })}
          >
            {activeForm && (
              <Flex className="work-info-form__form-header-wrapper">
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className="work-info-form__back-btn"
                  fixedWidth
                  color={COLORS.NEUTRAL600}
                  onClick={() => onGoBack(setValues, values)}
                />
                <Text className="work-info-form__form-header" variant={TEXT_VARIANTS.SUBHEADING}>
                  {makeLabel(`${activeForm}`)}
                </Text>
              </Flex>
            )}
            {activeForm === 'workPreferences' && (
              <>
                <Flex className="work-info-form__label-optional-wrapper">
                  <Text className="work-info-form__label" variant={TEXT_VARIANTS.H6}>
                    {CREATOR_ACCOUNT_SETTINGS_COPY.SUBSECTION_TITLE_WORK_PREF}
                  </Text>
                </Flex>
                <WorkPreferencesFormFields className="work-info-form__subheader" />
              </>
            )}
            {activeForm === 'model' && (
              <>
                <Flex className="work-info-form__label-optional-wrapper">
                  <Text className="work-info-form__label" variant={TEXT_VARIANTS.H6}>
                    {CREATOR_ACCOUNT_SETTINGS_COPY.SUBSECTION_TITLE_MODELS}
                  </Text>
                  <Text className="work-info-form__optional">Optional</Text>
                </Flex>
                <ModelAvailabilityFormFields className="work-info-form__subheader" />
              </>
            )}
            {activeForm === 'location' && (
              <>
                <Flex className="work-info-form__label-optional-wrapper">
                  <Text className="work-info-form__label" variant={TEXT_VARIANTS.H6}>
                    {CREATOR_ACCOUNT_SETTINGS_COPY.SUBSECTION_TITLE_LOCATION}
                  </Text>
                  <Text className="work-info-form__optional">Optional</Text>
                </Flex>
                <LocationFormFields className="work-info-form__subheader" />
              </>
            )}
            {activeForm === null && (
              <>
                {isMobile && (
                  <Flex className="work-info-form__form-header-wrapper">
                    <FontAwesomeIcon
                      icon={faAngleLeft}
                      className="work-info-form__back-btn"
                      fixedWidth
                      color={COLORS.NEUTRAL600}
                      onClick={onNavigateBack}
                    />
                    <Text className="work-info-form__form-header" variant={TEXT_VARIANTS.SUBHEADING}>
                      Work Info
                    </Text>
                  </Flex>
                )}
                {Object.values(FIELDNAMES).map((name) => (
                  <Box
                    key={name}
                    onClick={() => setActiveForm(name)}
                    className={classNames('work-info-form__btn', {
                      'work-info-form__btn--active': activeForm === name,
                    })}
                  >
                    <Flex className="work-info-form__btn-content">
                      <Flex className="work-info-form__btn-text-wrapper">{getFormDisplayValue(name, values)}</Flex>
                      <FontAwesomeIcon icon={faAngleRight} className="work-info-form__right-icon" fixedWidth />
                    </Flex>
                  </Box>
                ))}
              </>
            )}
            {activeForm && (
              <Flex className="work-info-form__btn-wrapper">
                <Button
                  variant={BUTTON_VARIANTS.OPTION}
                  className="work-info-form__cancel-btn"
                  onClick={() => onCancel(setValues, values)}
                  type="button"
                >
                  Cancel
                </Button>
                <FormButton type="submit" disableUntilTouched>
                  Save Changes
                </FormButton>
              </Flex>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default WorkInfoForm;
