import React from 'react';
import classnames from 'classnames';

import { ContributorAssessment, ContributorImpact } from 'features/accountPlatforms/types';
import { Flex, Text } from 'shared/components/display';

import { intl } from 'shared/lib/intl';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';

import getStyles from './accountHealthContributorStyles';
interface Props {
  title: string;
  score: string;
  description: string;
  impact: ContributorImpact;
  isExpanded?: boolean;
  assessment: ContributorAssessment;
  onClick?: () => void;
  isRatio: boolean;
}

const AccountHealthContributor = ({
  title,
  score,
  description,
  impact,
  assessment,
  isExpanded = false,
  onClick,
  isRatio,
}: Props) => {
  const formatMessage = intl.formatMessage;
  const getImpactColor = () => {
    switch (assessment) {
      case ContributorAssessment.BAD:
        return COLORS.RED500;
      case ContributorAssessment.OKAY:
        return COLORS.ORANGE500;
      default:
        return COLORS.GREEN500;
    }
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Flex
      css={getStyles({
        color: getImpactColor(),
      })}
      className={classnames('account-health-contributor', {
        'account-health-contributor--expanded': isExpanded,
      })}
      onClick={handleClick}
    >
      <Flex className="account-health-contributor__top-section">
        <Flex className="account-health-contributor__top-section-text">
          <Text variant={TEXT_VARIANTS.SUBHEADING} className="account-health-contributor__title">
            {title}
          </Text>
          <Text variant={TEXT_VARIANTS.SUBHEADING} className="account-health-contributor__value">
            {`${Math.floor(parseFloat(score))}${isRatio ? '%' : ''}`}
          </Text>
        </Flex>
      </Flex>

      <Flex
        className={classnames('account-health-contributor__bottom-section', {
          'account-health-contributor__bottom-section--expanded': isExpanded,
        })}
      >
        <Text variant={TEXT_VARIANTS.BODY} className="account-health-contributor__description">
          {description}
        </Text>

        {isExpanded && (
          <Text variant={TEXT_VARIANTS.SUBHEADING} className="account-health-contributor__impact">
            {formatMessage({ id: 'LABEL_CONTRIBUTOR_IMPACT' }, { impact: ContributorImpact[impact].toLowerCase() })}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default AccountHealthContributor;
