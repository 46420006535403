export const ACCOUNT_HEALTH_IMPACT = {
  HIGH_IMPACT: 'HIGH_IMPACT' as const,
  MEDIUM_IMPACT: 'MEDIUM_IMPACT' as const,
  LOW_IMPACT: 'LOW_IMPACT' as const,
};

export const ACCOUNT_HEALTH_ASSESMENT = {
  HIGH_IMPACT: 'HIGH_IMPACT' as const,
  MEDIUM_IMPACT: 'MEDIUM_IMPACT' as const,
  LOW_IMPACT: 'LOW_IMPACT' as const,
};

export enum ContributorImpact {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
}
export enum ContributorAssessment {
  BAD = 0,
  OKAY = 1,
  GOOD = 2,
}

export interface AccountPlatformHealthContributor {
  accountPlatformHealthId: number;
  contributorId: number;
  value: number;
  title: string;
  impact: ContributorImpact;
  contributorScore: string;
  assessment: ContributorAssessment;
  description: string;
  isRatio: boolean;
  badValue: number;
  goodValue: number;
}

export interface AccountPlatformHealth {
  dateCreated: Date;
  score: number;
  accountPlatformId: number;
  accountPlatformHealthContributors: AccountPlatformHealthContributor[];
}

export interface AdHealthSurveyPayload {
  score: 0 | 1;
  npsScore: number;
  feedback: string;
  adAccountName: string;
  adAccountId: string;
  internal: boolean;
  timestamp: Date;
  name: string;
  organizationId?: number;
  brkfstAccountId?: number;
  accountPlatformId?: number;
}
