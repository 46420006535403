import React, { useState } from 'react';
import CreatorBriefStartingSteps from 'features/briefs/components/organisms/CreatorBriefStartingSteps';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import ActionButton from 'shared/components/molecules/ActionButton';
import { BRIEF_COPY } from 'shared/config/copy';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';

interface Props {
  userId: number;
  briefId: number;
  full?: boolean;
  className?: string;
}

const BriefAcceptInviteButton: React.FC<Props> = ({ userId, briefId, full, className }) => {
  const [open, setOpen] = useState(false);
  const { brief, acceptBriefInvite, acceptBriefInviteActionInProgress } = useBriefs(briefId);

  const onCancel = () => {
    setOpen(false);
  };

  const clickHandler = () => {
    const { briefCreatorStatus, shippingRequired, briefCreatorAutoApproved, states } = brief;
    const hasStateRequirements = states && states.length > 0;
    const showAnyModals = hasStateRequirements || shippingRequired || !briefCreatorAutoApproved;

    if (briefCreatorStatus === UserBriefStatus.INVITED) {
      if (showAnyModals) {
        setOpen(true);
      } else {
        acceptBriefInvite({ id: briefId });
      }
    }
  };

  const onSubmit = (values: { pitch: string; states: { checked: boolean; value: number }[] }) => {
    const statesChosen = (values.states || []).filter(({ checked }) => checked).map(({ value }) => value);
    acceptBriefInvite({ id: briefId, briefInStoreRequirementIds: statesChosen, pitch: values.pitch });
  };

  return (
    <>
      <ActionButton
        onClick={clickHandler}
        loading={acceptBriefInviteActionInProgress}
        className={className}
        disabled={full}
        dataCy="brief-accept-invite-button"
      >
        {BRIEF_COPY.BUTTON_ACCEPT_INVITE}
      </ActionButton>
      <CreatorBriefStartingSteps brief={brief} userId={userId} onSubmit={onSubmit} onCancel={onCancel} open={open} />
    </>
  );
};

export default BriefAcceptInviteButton;
