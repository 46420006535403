import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.creator-brief-starting-steps': {
    '& .checkbox-group, & .address-form-fields, & .creator-brief-starting-steps__pitch': {
      padding: `0 ${theme.space[5]}`,
    },
  },
  '.creator-brief-starting-steps': {
    '&__pitch__subheading': {
      padding: '0 1rem',
      whiteSpace: 'pre-wrap',
      lineHeight: '1.2',
    },
    '&__shipping__subheading': {
      textAlign: 'center',
      whiteSpace: 'pre-line',
    },
    '&__states-subheading': {
      padding: '0 1rem',
    },
  },
});
