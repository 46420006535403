import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { faCircleXmark, faThumbsDown, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Field, Form, Formik, useFormikContext } from 'formik';

import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import { adHealthSurveyClosed } from 'features/ui/ui.slice';
import { Box, Button, Text, Flex } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';

import { GENERIC_COPY } from 'shared/config/copy';
import { TEXT_VARIANTS } from 'shared/styles/text';

import surveyDrawerStyles from './styles';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { AdHealthSurveyPayload } from 'features/accountPlatforms/types';
import { makeArray } from 'shared/lib/formik';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import { schema } from './validation';
import { trackEvent } from 'shared/utilities/trackingUtility';
import { TRACKING } from 'shared/config/tracking';

const ONE_WEEK = 7 * 24 * 60 * 60 * 1000; // Milliseconds in one week
function isSurveyExpired(dateSubmitted: Date) {
  const diff = Math.abs(new Date().getTime() - new Date(dateSubmitted).getTime());
  return diff > ONE_WEEK;
}

type RouteParams = {
  organizationId: string;
  accountId: string;
};

interface Props {
  internal?: boolean;
  accountId: number;
  adAccountName: string;
  adAccountId: string;
  accountPlatformId?: number;
}

const SurveyDrawer: React.FC<Props> = ({
  internal = false,
  accountId,
  adAccountName,
  adAccountId,
  accountPlatformId,
}) => {
  const { submitAdHealthSurvey, showAdHealthSurvey } = useAccountPlatforms({ accountId });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const params = useParams<RouteParams>();
  const dispatch = useDispatch();
  const { value: ahsDateSubmitted, setVal } = useLocalStorage<Date>(`ahsDateSubmitted_${adAccountId}`);
  const initialValues = {
    score: 1,
    feedback: '',
    nps: undefined,
  };

  const handleSubmit = (values, actions) => {
    const payload: AdHealthSurveyPayload = {
      score: values.score,
      feedback: values.feedback,
      npsScore: values.nps,
      adAccountName,
      adAccountId: adAccountId.toString(),
      internal: internal,
      timestamp: new Date(),
      name: 'ad_health',
    };
    if (internal) {
      payload.accountPlatformId = accountPlatformId;
      payload.brkfstAccountId = accountId;
      payload.organizationId = +params.organizationId;
    }
    submitAdHealthSurvey(payload, () => {
      setIsSubmitted(true);
      setVal(new Date());
    });
    trackEvent(TRACKING.ADSCORE_SURVEY_COMPLETED);
    actions.setSubmitting(false);
  };

  const showSurvey = useMemo(() => {
    if (showAdHealthSurvey && ahsDateSubmitted) return isSubmitted || isSurveyExpired(ahsDateSubmitted);
    return showAdHealthSurvey;
  }, [showAdHealthSurvey, ahsDateSubmitted, isSubmitted]);

  useEffect(() => {
    trackEvent(TRACKING.ADSCORE_SURVEY_OPENED);
  }, [showSurvey]);

  const onClose = () => {
    dispatch(adHealthSurveyClosed());
  };

  return showSurvey ? (
    <>
      <Box className={classNames('survey-drawer', { 'survey-drawer--open': showSurvey })} css={surveyDrawerStyles}>
        <Text variant={TEXT_VARIANTS.SUBHEADING} className="survey-drawer__title">
          Tell us what you think about the score
        </Text>
        <FontAwesomeIcon icon={faCircleXmark} className="survey-drawer__close" onClick={onClose} />
        {isSubmitted ? (
          <Box>
            <Text>Thanks for taking the time to share your feedback with us today.</Text>
            <Button className="survey-drawer__button" onClick={onClose}>
              Close
            </Button>
          </Box>
        ) : (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
            {({ isSubmitting, setFieldValue, values }) => (
              <Form className="survey-drawer__form">
                <Box className="survey-drawer__form-container">
                  <label htmlFor="nps" className="survey-drawer__label">
                    On a scale of 0 to 10, how likely are you to recommend Ad Health Score to a friend or colleague?
                  </label>
                  <NpsInput name="nps" />
                  <label htmlFor="feedback" className="survey-drawer__label">
                    Do you feel like your Ad Health score is accurate?
                  </label>
                  <Box className="survey-drawer__thumbs">
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                      className={classNames('survey-drawer__thumb', {
                        'survey-drawer__thumb--selected': values.score === 1,
                      })}
                      onClick={() => setFieldValue('score', 1)}
                    />
                    <FontAwesomeIcon
                      icon={faThumbsDown}
                      className={classNames('survey-drawer__thumb', {
                        'survey-drawer__thumb--selected': values.score === 0,
                      })}
                      onClick={() => setFieldValue('score', 0)}
                    />
                  </Box>

                  <label htmlFor="feedback" className="survey-drawer__label">
                    Additional Feedback
                  </label>
                  <Field
                    as="textarea"
                    name="feedback"
                    placeholder="Enter your feedback here"
                    className="survey-drawer__textarea"
                  />
                  <FormButton disabled={isSubmitting} className="survey-drawer__button">
                    {GENERIC_COPY.BUTTON_SUBMIT}
                  </FormButton>
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </>
  ) : (
    <></>
  );
};

const NpsInput = makeArray<number, { values?: number }>(({ name, values: nps }) => {
  const { setFieldValue } = useFormikContext();
  return (
    <Box>
      <Box className="survey-drawer__npsscore">
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
          <Field
            name={name}
            component={Button}
            type="button"
            variant={BUTTON_VARIANTS.TEXT_ONLY}
            key={`nps_${num}`}
            className={classNames('survey-drawer__nps', {
              'survey-drawer__nps--selected': nps === num,
            })}
            onClick={() => {
              setFieldValue('nps', num);
            }}
          >
            {num}
          </Field>
        ))}
      </Box>
      <FieldErrorMessage name={name} />
    </Box>
  );
});

export default SurveyDrawer;
