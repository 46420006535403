import React from 'react';
import cs from 'classnames';

import { Button } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import { Option } from 'shared/components/molecules/ButtonGroupInput/ButtonGroupInput';

import { Input } from 'shared/lib/formik';
import { BUTTON_VARIANTS } from 'shared/styles/button';

import styles from './styles';
interface Props {
  disabled?: boolean;
  label: string;
  onChange?: (value: string, selected?: boolean) => void;
  className?: string;
  variant?: BUTTON_VARIANTS;
  children?: React.ReactNode;
  dataCy?: string;
  showError?: boolean;
}

const ButtonInput: Input<Option, Props> = ({
  field: { name, value, onChange: formikOnChange, onBlur },
  disabled = false,
  onChange,
  className,
  variant,
  children,
  dataCy,
  showError = true,
}) => {
  const onChangeCallback = (event) => {
    event.persist();
    formikOnChange({ target: { value: { ...value, selected: !value.selected }, name } });
    if (onChange) onChange(value.value, !value.selected);
    onBlur(event);
  };

  return (
    <>
      <Button
        css={styles}
        disabled={disabled}
        variant={variant}
        onClick={onChangeCallback}
        children={children}
        className={cs('btn-input__option', className, {
          'btn-input__option--selected': value.selected,
        })}
        type="button"
        data-cy={dataCy}
      />
      {showError && <FieldErrorMessage name={name} dataCy={`${dataCy}__error-message`} />}
    </>
  );
};

export default ButtonInput;
